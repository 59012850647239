import http from 'hub-http/clients/apiClient';
import getTasksStore from 'getting-started-shared-tasks-store/store';
import retryClient from './api/retryClient';
import { setGroupKey } from './api/userContextApi';
import { setOnboardingSettings } from './onboardingSettings';
export const USER_CONTEXT_ACTIONS_BASE_URL = 'usercontext/v1/actions';
export const USER_CONTEXT_APP_BASE_URL = 'usercontext-app/v1';
export const enableTaskCompletionModal = (actionName, options = {}) => {
  const {
    targetWindow = window
  } = options;
  const bannerFrame = targetWindow.document.getElementById('onboarding-tours-banner-frame');
  if (!window.sessionStorage || !bannerFrame || !bannerFrame.contentWindow) {
    return;
  }
  try {
    const configKey = 'ONBOARDING_TOURS_GUIDE';
    const configJson = window.sessionStorage.getItem(configKey);
    if (!configJson) {
      return;
    }
    const config = JSON.parse(configJson);
    if (config.type === 'task' && config.key === actionName) {
      config.completed = true;
      bannerFrame.contentWindow.postMessage({
        key: configKey,
        value: config
      }, '*');
    }
  } catch (e) {
    return;
  }
};
const getTaskDataSource = actionName => {
  try {
    var _getTasksStore$getTas;
    return (_getTasksStore$getTas = getTasksStore().getTaskStartedWithingTimeframe(actionName)) === null || _getTasksStore$getTas === void 0 ? void 0 : _getTasksStore$getTas.source;
  } catch (e) {
    return undefined;
  }
};

/**
 *  @param actionName task key
 *  @param options.showTaskCompletionModal show task completion modal from handholding banner
 */
export const markActionComplete = (actionName, options = {}) => {
  return retryClient.post(USER_CONTEXT_ACTIONS_BASE_URL, {
    data: {
      actionName,
      actionOrigin: getTaskDataSource(actionName)
    }
  }).then(() => {
    const {
      showTaskCompletionModal = true,
      targetMessageWindow = window,
      source
    } = options;
    if (!showTaskCompletionModal) {
      return;
    }

    /**
     * Ignore task completion modal for avoiding breaking onboarding tour when:
     *   - Any onboarding tour is active in current page
     *   - markActionComplete is not triggered from onboarding-tours lib
     * */
    if (source !== 'onboarding-tours' && document.querySelector('.onboarding-tour--active')) {
      return;
    }
    enableTaskCompletionModal(actionName, {
      targetWindow: targetMessageWindow
    });
  });
};
export const getCompletedActions = () => http.get(USER_CONTEXT_ACTIONS_BASE_URL);
export const getIsActionCompleted = actionName => http.get(`${USER_CONTEXT_ACTIONS_BASE_URL}/${actionName}`);
export const getTasksGroup = () => {
  return http.get(`${USER_CONTEXT_APP_BASE_URL}/onboarding/tasks/group`, {
    headers: {
      accept: 'text/plain'
    }
  });
};
export const setDefaultGroupKey = (groupKey, options) => setGroupKey(groupKey, options);
export const setGettingStartedGroupKey = (groupKey, view, userId, portalId) => {
  const groupKeyRequests = [setDefaultGroupKey(groupKey)];
  if (view && userId) {
    groupKeyRequests.push(setOnboardingSettings({
      views: {
        [view]: {
          groupKey
        }
      },
      selectedView: view
    }, userId, portalId));
  }
  return Promise.all(groupKeyRequests);
};