import { toDOMRect } from '../utils/elementUtils';
export class PulserSpotlight {
  /**
   * Generate SVG path for pulser background overlay and highlighted boarder
   *
   * For pulser background overlay:
   *   Two rectangle will be drawn, the background overlay will be the difference set of A to B:
   *      A. Rectangle with full screen size
   *      B. Rectangle covered highlighted area
   *
   * For highlighted boarder:
   *   A rectangle with solid line will be drawn around highlighted area
   *
   * M,V,H are all SVG path commands:
   * See {@link https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/d#path_commands}
   */
  static createRectPath(rect, options) {
    const {
      radius = 0
    } = options;
    const {
      left,
      right,
      top,
      bottom
    } = rect;
    return [
    // Draw a rectangle around the target
    `M${left + radius},${top}a${radius},${radius},0,0,0-${radius},${radius}`, `V${bottom - radius}a${radius},${radius},0,0,0,${radius},${radius}`, `H${right - radius}a${radius},${radius},0,0,0,${radius}-${radius}`, `V${top + radius}a${radius},${radius},0,0,0-${radius}-${radius}Z`].join('');
  }
  static generatePulserPath(options) {
    const {
      distance,
      padding,
      targetRect
    } = options;
    const pulserPath = {
      start: this.createRectPath(toDOMRect(targetRect, padding), options)
    };
    const isAnimated = distance !== undefined && distance !== 0;
    if (isAnimated) {
      const endPath = toDOMRect(targetRect, padding + distance);
      pulserPath.end = this.createRectPath(endPath, options);
    }
    return pulserPath;
  }
}