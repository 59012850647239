'use es6';

/* eslint-disable quotes */
export default {
  "supported": {
    "DE": true,
    "EN": true,
    "ES": true,
    "FI": true,
    "FR": true,
    "IT": true,
    "JA": true,
    "NL": true,
    "PL": true,
    "PT": true
  },
  "complete": {
    "DE": true,
    "EN": true,
    "ES": true,
    "FI": true,
    "FR": true,
    "IT": true,
    "JA": true,
    "NL": true,
    "PL": true,
    "PT": true
  },
  "customMessages": {
    "DE": {
      "followup_question": "Was könnten wir tun, um Ihr Nutzererlebnis zu verbessern?",
      "followup_question_nps": "Was ist der wichtigste Grund für Ihre Bewertung?",
      "followup_placeholder": "Helfen Sie uns, Ihre Bewertung besser zu verstehen.",
      "placeholder_text": "Helfen Sie uns, Ihre Bewertung besser zu verstehen.",
      "send": "O. K.",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Wie zufrieden sind Sie mit Ihrer Online-Account- und Abrechnungserfahrung?",
        "WORKFLOWS": "Wie zufrieden sind Sie mit der Funktion zum Automatisieren Ihrer Prozesse in HubSpot?",
        "INBOX": "Wie zufrieden sind Sie mit dem Verwalten von Konversationen in HubSpot?",
        "TRIAL_EXPIRATION": "Wie zufrieden sind Sie mit Ihrem Testlauferfahrung?",
        "TRIAL_EXPIRATION_DASHBOARD": "Wie zufrieden sind Sie mit Ihrem Testlauferfahrung?",
        "CMS_BRAND_SETTINGS": "Wie zufrieden sind Sie mit der Funktion zum Verwalten Ihrer Marke in HubSpot?",
        "CMS_CONTENT_DETAIL": "Wie zufrieden sind Sie mit der Funktion zum Verwalten von Webcontent in HubSpot?",
        "CMS_DESIGN_MANAGER": "Wie zufrieden sind Sie mit der Funktion zum Entwickeln Ihrer Website in HubSpot?",
        "ADVANCED_BUILDER": "Wie zufrieden sind Sie mit der Funktion zum Erhalten von Einblicken in HubSpot?",
        "FORECASTING": "Wie zufrieden sind Sie mit dem Verwalten Ihrer Deal-Pipeline in HubSpot?",
        "FORECASTING_SEPG": "Wie zufrieden sind Sie mit der Funktion zum effektiven Abschließen von Deals in HubSpot?",
        "SOCIAL": "Wie zufrieden sind Sie mit der Funktion zum Interagieren mit Ihrer Zielgruppe in HubSpot?",
        "MARKETING_EMAIL": "Wie zufrieden sind Sie mit der Funktion zum Pflegen Ihrer Zielgruppe in HubSpot?",
        "FORMS": "Wie zufrieden sind Sie mit der Funktion zum Erfassen neuer Leads in HubSpot?",
        "CAMPAIGNS": "Wie zufrieden sind Sie mit der Funktion zum Optimieren Ihrer Marketingmaßnahmen in HubSpot?",
        "DEAL": "Wie zufrieden sind Sie mit dem Verwalten Ihrer Deal-Pipeline in HubSpot?",
        "ADS": "Wie zufrieden sind Sie mit der Funktion zum Interagieren mit Ihrer Zielgruppe in HubSpot?",
        "TASKS": "Wie zufrieden sind Sie mit dem Verwalten der Produktivität im Vertrieb in HubSpot?",
        "SEQUENCES": "Wie zufrieden sind Sie mit der Kontaktaufnahme Ihres Vertriebs in HubSpot?",
        "QUOTES": "Wie zufrieden sind Sie mit der Funktion zum Verwalten von Angeboten in HubSpot?",
        "ANALYTICS": "Wie zufrieden sind Sie mit der Funktion zum Gewinnen von Einblicken in HubSpot?",
        "INDEX_CRM": "Wie zufrieden sind Sie mit den Informationen, die Ihnen in HubSpot bereitgestellt werden, um Maßnahmen zu ergreifen?",
        "RECORD_CRM": "Wie zufrieden sind Sie mit den Informationen, die Ihnen in HubSpot bereitgestellt werden, um Maßnahmen zu ergreifen?",
        "CHAT": "Wie zufrieden sind Sie mit dem Verwalten von Chats in HubSpot?",
        "DATA_SYNC": "Wie zufrieden sind Sie mit der Funktion zum Synchronisieren von Daten in HubSpot?",
        "CRM_LISTS": "Wie zufrieden sind Sie mit der Funktion zum effektiven Segmentieren von Daten in HubSpot?",
        "CRM_SETTINGS": "Wie zufrieden sind Sie mit der Funktion zum Zuordnen Ihrer Unternehmensdaten in HubSpot?",
        "PAYMENTS": "Wie zufrieden sind Sie mit dem Verwalten von Zahlungen in HubSpot?",
        "ADMIN_SETUP": "Wie zufrieden sind Sie mit der Funktion, mit der Sie Ihre Benutzer in HubSpot auf Erfolgskurs bringen können?",
        "IMPORT": "Wie zufrieden sind Sie mit dem Datenimport in HubSpot?",
        "ADMIN_GOVERNANCE": "Wie zufrieden sind Sie mit der Kontrolle, die Sie über Ihren HubSpot-Account haben (als Super-Admin für Ihr Portal)?",
        "SINGLE_REPORT_VIEWER": "Wie zufrieden sind Sie mit den Einblicken, die die HubSpot-Berichterstattung liefert?",
        "REPORT_LIBRARY": "Wie zufrieden sind Sie mit den von HubSpot bereitgestellten Standardberichten?",
        "JOURNEY_ANALYTICS": "Wie zufrieden sind Sie mit den Einblicken, die die HubSpot-Berichterstattung liefert?",
        "SINGLE_OBJECT_BUILDER": "Wie zufrieden sind Sie mit den Einblicken, die die HubSpot-Berichterstattung liefert?",
        "MULTI_TOUCH_ATTRIBUTION": "Wie zufrieden sind Sie mit den Einblicken, die die HubSpot-Berichterstattung liefert?",
        "REPORT_DASHBOARD_VIEW": "Wie zufrieden sind Sie mit der Funktion zum Erhalten von Einblicken in HubSpot?",
        "PROSPECTING_MEETINGS": "Wie zufrieden sind Sie mit der Funktion zum effektiven Qualifizieren von Leads in HubSpot?",
        "PROSPECTING_MANAGE_SEQUENCES": "Wie zufrieden sind Sie mit der Funktion zum effektiven Qualifizieren von Leads in HubSpot?",
        "PROSPECTING_TASKS": "Wie zufrieden sind Sie mit der Funktion zum effektiven Qualifizieren von Leads in HubSpot?",
        "SALES_EXECUTION_DEAL_PIPELINE": "Wie zufrieden sind Sie mit der Funktion zum effektiven Abschließen von Deals in HubSpot?",
        "SALES_EXECUTION_DEALS": "Wie zufrieden sind Sie mit der Funktion zum effektiven Abschließen von Deals in HubSpot?",
        "SH_DEALS_WORKSPACE": "Wie zufrieden sind Sie mit der Funktion zum effektiven Abschließen von Deals in HubSpot?",
        "PROSPECTING_WORKSPACE": "Wie zufrieden sind Sie mit der Funktion zum effektiven Qualifizieren von Leads in HubSpot?",
        "CSAT_SVH_KNOWLEDGEBASE": "Wie zufrieden sind Sie mit dem Kundensupport von HubSpot?",
        "CSAT_SVH_HELPDESK": "Wie zufrieden sind Sie mit dem Kundensupport von HubSpot?",
        "CSAT_SVH_TICKET_DEFAULTS": "Wie zufrieden sind Sie mit dem Kundensupport von HubSpot?",
        "CSAT_SVH_HELPDESK_USER": "Wie zufrieden sind Sie mit dem Kundensupport von HubSpot?",
        "CSAT_SVH_LIVE_CHAT": "Wie zufrieden sind Sie mit dem Kundensupport von HubSpot?",
        "CSAT_SVH_BOT_MESSENGER": "Wie zufrieden sind Sie mit dem Kundensupport von HubSpot?",
        "CSAT_CRM_CONNECTED_APPS": "Wie zufrieden sind Sie mit der Funktion zum Verwenden von Integrationen in HubSpot?",
        "CSAT_CRM_CREATE_CUSTOM_OBJECT": "Wie zufrieden sind Sie mit dem Zuordnen Ihrer Unternehmensdaten in HubSpot?",
        "CSAT_CRM_VIEW_CUSTOM_OBJECTS": "Wie zufrieden sind Sie mit dem Zuordnen Ihrer Unternehmensdaten in HubSpot?",
        "HELPDESK_CREATE_VIEWS": "Wie zufrieden sind Sie mit der Unterstützung Ihrer Kunden durch HubSpot?",
        "PRODUCTS": "Wie zufrieden sind Sie mit der Funktion zum Verwalten von Produkten Ihres Unternehmens in HubSpot?",
        "INVOICES": "Wie zufrieden sind Sie mit der Funktion zur Rechnungsstellung mithilfe von HubSpot?",
        "CMS_SEO_RECS": "Wie zufrieden sind Sie mit den Vorschlägen zur Content-Optimierung, die Sie erhalten haben?",
        "FILE_MANAGER_PAGE_VIEW": "Wie zufrieden sind Sie mit der Funktion zum Verwalten von digitalen Elementen in HubSpot?",
        "COMMERCE_SUBSCRIPTIONS": "Wie zufrieden sind Sie mit dem Verwalten von Abonnements in HubSpot?",
        "ECOSYSTEM_ACADEMY": "Wie zufrieden sind Sie mit Ihrer Lernerfahrung mit der HubSpot Academy?",
        "PAYMENT_LINKS": "Wie zufrieden sind Sie mit der Funktion zum Akzeptieren von Zahlungen in HubSpot?",
        "ECOSYSTEM_APP_MARKETPLACE": "Wie zufrieden sind Sie mit der Funktion zur Integration anderer Apps in HubSpot?",
        "ECOSYSTEM_NETWORK": "Wie zufrieden sind Sie mit der Funktion zum Austausch mit der Connect.com-Community?",
        "DATASETS": "Wie zufrieden sind Sie mit den Funktionen zur Verwaltung der Datenqualität in HubSpot?",
        "CTAS": "Wie zufrieden sind Sie mit der Funktion zum Interagieren mit Ihrer Zielgruppe in HubSpot?",
        "DEVELOPER_ACCOUNT": "Wie zufrieden sind Sie mit der Funktion zum Erstellen individueller Lösungen in HubSpot?",
        "DATA_QUALITY": "Wie zufrieden sind Sie mit der Verwaltung der Datenqualität in HubSpot?",
        "PLATFORM_DUPLICATE_INTERACTION": "Wie zufrieden sind Sie mit der Verwaltung der Datenqualität in HubSpot?",
        "CRM_DEVELOPMENT": "Wie zufrieden sind Sie mit der Funktion zum Erstellen individueller Lösungen in HubSpot?",
        "CSAT_CRM_CUSTOM_CARDS": "Wie zufrieden sind Sie mit der Anpassung Ihres Accounts in HubSpot?",
        "COMMERCE_HOME": "Wie zufrieden sind Sie mit den ersten Schritten für Commerce Hub?",
        "IAH_EXPERIENCE": "Wie zufrieden sind Sie mit der Hilfe?",
        "FORMS_VIS_EXPERIENCE": "Wie zufrieden sind Sie mit der Funktion zum Erfassen neuer Leads in HubSpot?",
        "CSAT_SVH_AI_AGENT": "Wie zufrieden sind Sie mit dem Kundensupport von HubSpot?",
        "DATA_MODEL_INTERACTION": "Wie zufrieden sind Sie mit der Funktion zum Zuordnen Ihrer Unternehmensdaten in HubSpot?",
        "CMS_GENERATE_PODCAST": "Wie zufrieden sind Sie mit Ihren Optionen zum Erstellen von Content in HubSpot?",
        "PLATFORM_EDIT_PERMISSIONS": "Wie zufrieden sind Sie mit der Funktion, mit der Sie Ihre Benutzer in HubSpot auf Erfolgskurs bringen können?",
        "CMS_remix": "Wie zufrieden sind Sie mit Ihren Optionen zum Erstellen von Inhalten in HubSpot?"
      },
      "wootric_recommend_target": "an Mitarbeiter oder Kollegen",
      "followup_thank_you": "Vielen Dank!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Wie einfach war es für Sie, die HubSpot-Funktionalität während dieses Testlaufs zu beurteilen?",
        "SETUP_COMPLETE": "Wie einfach war die Einrichtung von HubSpot für Sie?",
        "ACADEMY": "Wie einfach war die Verwendung der HubSpot Academy für Sie?",
        "PURCHASE_EXPERIENCE": "Wie einfach war es für Sie, HubSpot zu kaufen?"
      }
    },
    "EN": {
      "followup_question": "What could we do to improve your experience?",
      "followup_question_nps": "What's the most important reason for your score?",
      "followup_placeholder": "Help us improve by explaining your score.",
      "placeholder_text": "Help us improve by explaining your score.",
      "send": "Send",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "How satisfied are you with your online account and billing experience?",
        "WORKFLOWS": "How satisfied are you with your ability to automate your processes in HubSpot?",
        "INBOX": "How satisfied are you with managing conversations in HubSpot?",
        "TRIAL_EXPIRATION": "How satisfied are you with your overall Trial experience?",
        "TRIAL_EXPIRATION_DASHBOARD": "How satisfied are you with your overall Trial experience?",
        "CMS_BRAND_SETTINGS": "How satisfied are you with your ability to manage your brand in HubSpot?",
        "CMS_CONTENT_DETAIL": "How satisfied are you with your ability to manage web content in HubSpot?",
        "CMS_DESIGN_MANAGER": "How satisfied are you with your ability to develop your website in HubSpot?",
        "ADVANCED_BUILDER": "How satisfied are you with your ability to get insights in HubSpot?",
        "FORECASTING": "How satisfied are you with managing your deal pipeline in HubSpot?",
        "FORECASTING_SEPG": "How satisfied are you with your ability to effectively close deals in HubSpot?",
        "SOCIAL": "How satisfied are you with your ability to engage your audience using HubSpot?",
        "MARKETING_EMAIL": "How satisfied are you with your ability to nurture your audience using HubSpot?",
        "FORMS": "How satisfied are you with your ability to capture new leads using HubSpot?",
        "CAMPAIGNS": "How satisfied are you with your ability to optimize your marketing efforts using HubSpot?",
        "DEAL": "How satisfied are you with managing your deal pipeline in HubSpot?",
        "ADS": "How satisfied are you with your ability to engage your audience using HubSpot?",
        "TASKS": "How satisfied are you with managing sales productivity in HubSpot?",
        "SEQUENCES": "How satisfied are you with managing sales outreach in HubSpot?",
        "QUOTES": "How satisfied are you with your ability to manage quotes in HubSpot?",
        "ANALYTICS": "How satisfied are you with your ability to get insights in HubSpot?",
        "INDEX_CRM": "How satisfied are you with having the information you need to take action in HubSpot?",
        "RECORD_CRM": "How satisfied are you with having the information you need to take action in HubSpot?",
        "CHAT": "How satisfied are you with managing chat in HubSpot?",
        "DATA_SYNC": "How satisfied are you with your ability to sync data in HubSpot?",
        "CRM_LISTS": "How satisfied are you with your ability to segment data effectively in HubSpot?",
        "CRM_SETTINGS": "How satisfied are you with your ability to map your business’ data in HubSpot?",
        "PAYMENTS": "How satisfied are you with managing payments in HubSpot?",
        "ADMIN_SETUP": "How satisfied are you with your ability to set your users up for success in HubSpot?",
        "IMPORT": "How satisfied are you with importing data into HubSpot?",
        "ADMIN_GOVERNANCE": "How satisfied are you with the level of control that you have over your HubSpot account (as a super admin for your portal)?",
        "SINGLE_REPORT_VIEWER": "How satisfied are you with getting insights from HubSpot reporting?",
        "REPORT_LIBRARY": "How satisfied are you with out-of-the-box reports provided by HubSpot?",
        "JOURNEY_ANALYTICS": "How satisfied are you with getting insights from HubSpot reporting?",
        "SINGLE_OBJECT_BUILDER": "How satisfied are you with getting insights from HubSpot reporting?",
        "MULTI_TOUCH_ATTRIBUTION": "How satisfied are you with getting insights from HubSpot reporting?",
        "REPORT_DASHBOARD_VIEW": "How satisfied are you with your ability to get insights in HubSpot?",
        "PROSPECTING_MEETINGS": "How satisfied are you with your ability to effectively qualify leads in HubSpot?",
        "PROSPECTING_MANAGE_SEQUENCES": "How satisfied are you with your ability to effectively qualify leads in HubSpot?",
        "PROSPECTING_TASKS": "How satisfied are you with your ability to effectively qualify leads in HubSpot?",
        "SALES_EXECUTION_DEAL_PIPELINE": "How satisfied are you with your ability to effectively close deals in HubSpot?",
        "SALES_EXECUTION_DEALS": "How satisfied are you with your ability to effectively close deals in HubSpot?",
        "SH_DEALS_WORKSPACE": "How satisfied are you with your ability to effectively close deals in HubSpot?",
        "PROSPECTING_WORKSPACE": "How satisfied are you with your ability to effectively qualify leads in HubSpot?",
        "CSAT_SVH_KNOWLEDGEBASE": "How satisfied are you with using HubSpot for customer support?",
        "CSAT_SVH_HELPDESK": "How satisfied are you with using HubSpot for customer support?",
        "CSAT_SVH_TICKET_DEFAULTS": "How satisfied are you with using HubSpot for customer support?",
        "CSAT_SVH_HELPDESK_USER": "How satisfied are you with using HubSpot for customer support?",
        "CSAT_SVH_LIVE_CHAT": "How satisfied are you with using HubSpot for customer support?",
        "CSAT_SVH_BOT_MESSENGER": "How satisfied are you with using HubSpot for customer support?",
        "CSAT_CRM_CONNECTED_APPS": "How satisfied are you with your ability to use integrations within HubSpot?",
        "CSAT_CRM_CREATE_CUSTOM_OBJECT": "How satisfied are you with mapping your business's data to HubSpot?",
        "CSAT_CRM_VIEW_CUSTOM_OBJECTS": "How satisfied are you with mapping your business's data to HubSpot?",
        "HELPDESK_CREATE_VIEWS": "How satisfied are you with supporting your customers with HubSpot?",
        "PRODUCTS": "How satisfied are you with your ability to manage your company’s products in HubSpot?",
        "INVOICES": "How satisfied are you with your ability to invoice using HubSpot?",
        "CMS_SEO_RECS": "How satisfied are you with the content optimization suggestions you’ve been given?",
        "FILE_MANAGER_PAGE_VIEW": "How satisfied are you with your ability to manage digital assets in HubSpot?",
        "COMMERCE_SUBSCRIPTIONS": "How satisfied are you with managing subscriptions in HubSpot?",
        "ECOSYSTEM_ACADEMY": "How satisfied are you with your learning experience with HubSpot Academy?",
        "PAYMENT_LINKS": "How satisfied are you with your ability to accept payments in HubSpot?",
        "ECOSYSTEM_APP_MARKETPLACE": "How satisfied are you with your ability to integrate other apps with HubSpot?",
        "ECOSYSTEM_NETWORK": "How satisfied are you with your ability to engage with the Connect.com community?",
        "DATASETS": "How satisfied are you with your ability to manage data quality in HubSpot?",
        "CTAS": "How satisfied are you with your ability to engage your audience using HubSpot?",
        "DEVELOPER_ACCOUNT": "How satisfied are you with your ability to build custom solutions on HubSpot?",
        "DATA_QUALITY": "How satisfied are you with managing data quality in HubSpot?",
        "PLATFORM_DUPLICATE_INTERACTION": "How satisfied are you with managing data quality in HubSpot?",
        "CRM_DEVELOPMENT": "How satisfied are you with your ability to build custom solutions on HubSpot?",
        "CSAT_CRM_CUSTOM_CARDS": "How satisfied are you with how customized your account is in HubSpot?",
        "COMMERCE_HOME": "How satisfied are you with your ability to get started with Commerce Hub?",
        "IAH_EXPERIENCE": "How satisfied are you with your help experience?",
        "FORMS_VIS_EXPERIENCE": "How satisfied are you with your ability to capture new leads using HubSpot?",
        "CSAT_SVH_AI_AGENT": "How satisfied are you with using HubSpot for customer support?",
        "DATA_MODEL_INTERACTION": "How satisfied are you with your ability to map your business’ data in HubSpot?",
        "CMS_GENERATE_PODCAST": "How satisfied are you with your ability to create content in HubSpot?",
        "PLATFORM_EDIT_PERMISSIONS": "How satisfied are you with your ability to set your users up for success in HubSpot?",
        "CMS_remix": "How satisfied are you with your ability to create content in HubSpot?",
        "CS_WORKSPACE": "How satisfied are you with using HubSpot for customer success?"
      },
      "wootric_recommend_target": "a coworker or colleague",
      "followup_thank_you": "Thank you!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "How easy was it for you to assess HubSpot functionality during this trial?",
        "SETUP_COMPLETE": "How easy was it for you to get set up with HubSpot?",
        "ACADEMY": "How easy was it for you to use the Academy?",
        "PURCHASE_EXPERIENCE": "How easy was it for you to purchase HubSpot?"
      }
    },
    "ES": {
      "followup_question": "¿Qué podríamos hacer para mejorar tu experiencia?",
      "followup_question_nps": "¿Cuál es la razón más importante de tu calificación?",
      "followup_placeholder": "Ayúdanos a mejorar explicando tu calificación.",
      "placeholder_text": "Ayúdanos a mejorar explicando tu calificación.",
      "send": "Enviar",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "¿Cuál es tu grado de satisfacción con tu cuenta online y con tu experiencia de facturación?",
        "WORKFLOWS": "¿Cuánto te satisface tu capacidad de automatizar tus procesos en HubSpot?",
        "INBOX": "¿Cuál es tu grado de satisfacción al administrar tus conversaciones en HubSpot?",
        "TRIAL_EXPIRATION": "¿Cuál es tu grado de satisfacción con tu experiencia de prueba general?",
        "TRIAL_EXPIRATION_DASHBOARD": "¿Cuál es tu grado de satisfacción con tu experiencia de prueba general?",
        "CMS_BRAND_SETTINGS": "¿Cuál es tu grado de satisfacción con tu capacidad de gestionar tu marca en HubSpot?",
        "CMS_CONTENT_DETAIL": "¿Cuál es tu grado de satisfacción con tu capacidad de gestionar contenido web en HubSpot?",
        "CMS_DESIGN_MANAGER": "¿Cuál es tu grado de satisfacción con tu capacidad de desarrollar tu sitio web en HubSpot?",
        "ADVANCED_BUILDER": "¿Cuál es tu grado de satisfacción con tu capacidad para obtener información en HubSpot?",
        "FORECASTING": "¿Cuál es tu grado de satisfacción al administrar tu pipeline de negocios en HubSpot?",
        "FORECASTING_SEPG": "¿Cuál es tu grado de satisfacción con tu capacidad de cerrar negocios con eficacia en HubSpot?",
        "SOCIAL": "¿Cuál es tu grado de satisfacción con tu capacidad de interactuar con tu audiencia usando HubSpot?",
        "MARKETING_EMAIL": "¿Cuál es tu grado de satisfacción con tu capacidad de nutrir a tu audiencia usando HubSpot?",
        "FORMS": "¿Cuál es tu grado de satisfacción con tu capacidad de captar nuevos leads usando HubSpot?",
        "CAMPAIGNS": "¿Cuál es tu grado de satisfacción con tu capacidad de optimizar tus iniciativas de marketing usando HubSpot?",
        "DEAL": "¿Cuál es tu grado de satisfacción al administrar tu pipeline de negocios en HubSpot?",
        "ADS": "¿Cuál es tu grado de satisfacción con tu capacidad de interactuar con tu audiencia usando HubSpot?",
        "TASKS": "¿Cuál es tu grado de satisfacción al administrar la productividad de las ventas en HubSpot?",
        "SEQUENCES": "¿Cuál es tu grado de satisfacción al administrar el alcance de las ventas en HubSpot?",
        "QUOTES": "¿Cuán satisfecho estás con tu capacidad de gestionar cotizaciones en HubSpot?",
        "ANALYTICS": "¿Cuál es tu grado de satisfacción con tu capacidad para obtener información en HubSpot?",
        "INDEX_CRM": "¿Cuál es tu nivel de satisfacción en cuanto a tener la información que necesitas para realizar acciones en HubSpot?",
        "RECORD_CRM": "¿Cuál es tu nivel de satisfacción en cuanto a tener la información que necesitas para realizar acciones en HubSpot?",
        "CHAT": "¿Cuál es tu grado de satisfacción al administrar el chat en HubSpot?",
        "DATA_SYNC": "¿Cuál es tu grado de satisfacción con tu capacidad de sincronizar datos en HubSpot?",
        "CRM_LISTS": "¿Cuál es tu grado de satisfacción con tu capacidad para segmentar datos de forma eficaz en HubSpot?",
        "CRM_SETTINGS": "¿Cuál es tu grado de satisfacción con tu capacidad para asignar los datos de tu empresa en HubSpot?",
        "PAYMENTS": "¿Cuál es tu grado de satisfacción con la gestión de pagos en HubSpot?",
        "ADMIN_SETUP": "¿Cuánto te satisface tu capacidad de preparar a tus clientes para el éxito en HubSpot?",
        "IMPORT": "¿Cuál es tu grado de satisfacción con la importación de datos a HubSpot?",
        "ADMIN_GOVERNANCE": "¿Cuán satisfecho estás con el nivel de control que tienes sobre tu cuenta de HubSpot (como super administrador de tu portal)?",
        "SINGLE_REPORT_VIEWER": "¿Cuál es tu grado de satisfacción con la información que adquieres con los informes de HubSpot?",
        "REPORT_LIBRARY": "¿Cuál es tu grado de satisfacción con los informes de rápida instalación proporcionados por HubSpot?",
        "JOURNEY_ANALYTICS": "¿Cuál es tu grado de satisfacción con la información que adquieres con los informes de HubSpot?",
        "SINGLE_OBJECT_BUILDER": "¿Cuál es tu grado de satisfacción con la información que adquieres con los informes de HubSpot?",
        "MULTI_TOUCH_ATTRIBUTION": "¿Cuál es tu grado de satisfacción con la información que adquieres con los informes de HubSpot?",
        "REPORT_DASHBOARD_VIEW": "¿Cuál es tu grado de satisfacción con tu capacidad para obtener información en HubSpot?",
        "PROSPECTING_MEETINGS": "¿Cuál es tu grado de satisfacción con tu capacidad de calificar leads con eficacia en HubSpot?",
        "PROSPECTING_MANAGE_SEQUENCES": "¿Cuál es tu grado de satisfacción con tu capacidad de calificar leads con eficacia en HubSpot?",
        "PROSPECTING_TASKS": "¿Cuál es tu grado de satisfacción con tu capacidad de calificar leads con eficacia en HubSpot?",
        "SALES_EXECUTION_DEAL_PIPELINE": "¿Cuál es tu grado de satisfacción con tu capacidad de cerrar negocios con eficacia en HubSpot?",
        "SALES_EXECUTION_DEALS": "¿Cuál es tu grado de satisfacción con tu capacidad de cerrar negocios con eficacia en HubSpot?",
        "SH_DEALS_WORKSPACE": "¿Cuál es tu grado de satisfacción con tu capacidad de cerrar negocios con eficacia en HubSpot?",
        "PROSPECTING_WORKSPACE": "¿Cuál es tu grado de satisfacción con tu capacidad de calificar leads con eficacia en HubSpot?",
        "CSAT_SVH_KNOWLEDGEBASE": "¿Cuál es tu grado de satisfacción con el servicio de asistencia al cliente de HubSpot?",
        "CSAT_SVH_HELPDESK": "¿Cuál es tu grado de satisfacción con el servicio de atención al cliente de HubSpot?",
        "CSAT_SVH_TICKET_DEFAULTS": "¿Cuál es tu grado de satisfacción con el servicio de asistencia al cliente de HubSpot?",
        "CSAT_SVH_HELPDESK_USER": "¿Cuál es tu grado de satisfacción con el servicio de asistencia al cliente de HubSpot?",
        "CSAT_SVH_LIVE_CHAT": "¿Cuál es tu grado de satisfacción con el servicio de asistencia al cliente de HubSpot?",
        "CSAT_SVH_BOT_MESSENGER": "¿Cuál es tu grado de satisfacción con el servicio de asistencia al cliente de HubSpot?",
        "CSAT_CRM_CONNECTED_APPS": "¿Cuál es tu grado de satisfacción con tu capacidad de usar integraciones en HubSpot?",
        "CSAT_CRM_CREATE_CUSTOM_OBJECT": "¿Cuál es tu grado de satisfacción con la asignación de los datos de tu empresa en HubSpot?",
        "CSAT_CRM_VIEW_CUSTOM_OBJECTS": "¿Cuál es tu grado de satisfacción con la asignación de los datos de tu empresa en HubSpot?",
        "HELPDESK_CREATE_VIEWS": "¿Cuál es tu grado de satisfacción con la asistencia que brindas a tus clientes con HubSpot?",
        "PRODUCTS": "¿Cuál es tu grado de satisfacción con tu capacidad de gestionar los productos de tu empresa en HubSpot?",
        "INVOICES": "¿Cuál es tu grado de satisfacción con tu capacidad de facturar usando HubSpot?",
        "CMS_SEO_RECS": "¿Cuánto te satisfacen las sugerencias de optimización de contenido que te han dado?",
        "FILE_MANAGER_PAGE_VIEW": "¿Cuál es tu grado de satisfacción con tu capacidad de gestionar recursos digitales en HubSpot?",
        "COMMERCE_SUBSCRIPTIONS": "¿Cuál es tu grado de satisfacción al administrar tus suscripciones en HubSpot?",
        "ECOSYSTEM_ACADEMY": "¿Cuál es tu grado de satisfacción con tu experiencia de aprendizaje con HubSpot Academy?",
        "PAYMENT_LINKS": "¿Cuál es tu grado de satisfacción con tu capacidad para aceptar pagos en HubSpot?",
        "ECOSYSTEM_APP_MARKETPLACE": "¿Cuál es tu grado de satisfacción con tu capacidad de integrar otras aplicaciones con HubSpot?",
        "ECOSYSTEM_NETWORK": "¿Cuál es tu grado de satisfacción con tu capacidad de interactuar con la comunidad de Connect.com?",
        "DATASETS": "¿Cuán satisfecho estás con tu capacidad de gestionar la calidad de los datos en HubSpot?",
        "CTAS": "¿Cuál es tu grado de satisfacción con tu capacidad de interactuar con tu audiencia usando HubSpot?",
        "DEVELOPER_ACCOUNT": "¿Cuál es tu grado de satisfacción con tu capacidad para crear soluciones personalizadas en HubSpot?",
        "DATA_QUALITY": "¿Cuál es tu grado de satisfacción con la gestión de la calidad de los datos en HubSpot?",
        "PLATFORM_DUPLICATE_INTERACTION": "¿Cuál es tu grado de satisfacción con la gestión de la calidad de los datos en HubSpot?",
        "CRM_DEVELOPMENT": "¿Cuál es tu grado de satisfacción con tu capacidad para crear soluciones personalizadas en HubSpot?",
        "CSAT_CRM_CUSTOM_CARDS": "¿Cuál es tu grado de satisfacción con la personalización de tu cuenta en HubSpot?",
        "COMMERCE_HOME": "¿Cuál es tu grado de satisfacción con tu capacidad de comenzar a usar Commerce Hub?",
        "IAH_EXPERIENCE": "¿Cuál es tu grado de satisfacción con la experiencia de ayuda?",
        "FORMS_VIS_EXPERIENCE": "¿Cuál es tu grado de satisfacción con tu capacidad de captar nuevos leads usando HubSpot?",
        "CSAT_SVH_AI_AGENT": "¿Cuál es tu grado de satisfacción con el servicio de asistencia al cliente de HubSpot?",
        "DATA_MODEL_INTERACTION": "¿Cuál es tu grado de satisfacción con tu capacidad para asignar los datos de tu empresa en HubSpot?",
        "CMS_GENERATE_PODCAST": "¿Cuánto te satisface la capacidad de crear contenido en HubSpot?",
        "PLATFORM_EDIT_PERMISSIONS": "¿Cuánto te satisface tu capacidad de preparar a tus clientes para el éxito en HubSpot?",
        "CMS_remix": "¿Cuánto te satisface la capacidad de crear contenido en HubSpot?"
      },
      "wootric_recommend_target": "un compañero de trabajo o colega",
      "followup_thank_you": "¡Gracias!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "¿Qué tan fácil te resultó evaluar la funcionalidad de HubSpot durante esta prueba?",
        "SETUP_COMPLETE": "¿Cómo de fácil te pareció la configuración de HubSpot?",
        "ACADEMY": "¿Qué tan fácil te resultó usar HubSpot Academy?",
        "PURCHASE_EXPERIENCE": "¿Qué tan fácil te resulto comprar HubSpot?"
      }
    },
    "FI": {
      "followup_question": "Mitä voisimme tehdä parantaaksemme kokemustasi?",
      "followup_question_nps": "Mikä on tärkein syy pistemäärääsi?",
      "followup_placeholder": "Auta meitä parantamaan selittämällä arvosanasi.",
      "placeholder_text": "Auta meitä parantamaan selittämällä arvosanasi.",
      "send": "Lähetä",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Kuinka tyytyväinen olet verkkotiliisi ja laskutuskokemukseesi?",
        "WORKFLOWS": "Kuinka tyytyväinen olet mahdollisuuteesi automatisoida prosesseja HubSpotissa?",
        "INBOX": "Kuinka tyytyväinen olet HubSpotin keskusteluiden hallintaan?",
        "TRIAL_EXPIRATION": "Kuinka tyytyväinen olet yleisesti kokeilujaksoosi?",
        "TRIAL_EXPIRATION_DASHBOARD": "Kuinka tyytyväinen olet yleisesti kokeilujaksoosi?",
        "CMS_BRAND_SETTINGS": "Kuinka tyytyväinen olet mahdollisuuteesi hallita brändiäsi HubSpotissa?",
        "CMS_CONTENT_DETAIL": "Kuinka tyytyväinen olet mahdollisuuteesi hallita verkkosisältöä HubSpotissa?",
        "CMS_DESIGN_MANAGER": "Kuinka tyytyväinen olet mahdollisuuteesi kehittää verkkosivustoasi HubSpotissa?",
        "ADVANCED_BUILDER": "Kuinka tyytyväinen olet mahdollisuuteesi saada olennaisia tietoja HubSpotissa?",
        "FORECASTING": "Kuinka tyytyväinen olet kauppojen myyntiputken hallinnointiin HubSpotissa?",
        "FORECASTING_SEPG": "Kuinka tyytyväinen olet mahdollisuuteesi sulkea kauppoja tehokkaasti HubSpotissa?",
        "SOCIAL": "Kuinka tyytyväinen olet mahdollisuuteesi olla vuorovaikutuksessa yleisösi kanssa HubSpotissa?",
        "MARKETING_EMAIL": "Kuinka tyytyväinen olet mahdollisuuteesi hoivata yleisöäsi käyttämällä HubSpotia?",
        "FORMS": "Kuinka tyytyväinen olet mahdollisuuteesi kerätä uusia liidejä HubSpotissa?",
        "CAMPAIGNS": "Kuinka tyytyväinen olet mahdollisuuteesi optimoida markkinointitoimiasi käyttämällä HubSpotia?",
        "DEAL": "Kuinka tyytyväinen olet kauppojen myyntiputken hallinnointiin HubSpotissa?",
        "ADS": "Kuinka tyytyväinen olet mahdollisuuteesi olla vuorovaikutuksessa yleisösi kanssa HubSpotissa?",
        "TASKS": "Kuinka tyytyväinen olet myynnin tuottavuuden hallintaan HubSpotissa?",
        "SEQUENCES": "Kuinka tyytyväinen olet myynnin ulottuvuuden hallintaan HubSpotissa?",
        "QUOTES": "Kuinka tyytyväinen olet mahdollisuuteesi hallita tarjouksia HubSpotissa?",
        "ANALYTICS": "Kuinka tyytyväinen olet mahdollisuuteesi saada olennaisia tietoja HubSpotissa?",
        "INDEX_CRM": "Kuinka tyytyväinen olet tietoihin, joita tarvitset toimenpiteiden tekemiseen HubSpotissa?",
        "RECORD_CRM": "Kuinka tyytyväinen olet tietoihin, joita tarvitset toimenpiteiden tekemiseen HubSpotissa?",
        "CHAT": "Kuinka tyytyväinen olet chatin hallinnointiin HubSpotissa?",
        "DATA_SYNC": "Kuinka tyytyväinen olet kykyysi synkronoida tietoja HubSpotissa?",
        "CRM_LISTS": "Kuinka tyytyväinen olet kykyysi segmentoida tietoja tehokkaasti HubSpotissa?",
        "CRM_SETTINGS": "Kuinka tyytyväinen olet mahdollisuuteesi kartoittaa yrityksesi tietoja HubSpotissa?",
        "PAYMENTS": "Kuinka tyytyväinen olet maksujen hallintaan HubSpotissa?",
        "ADMIN_SETUP": "Kuinka tyytyväinen olet mahdollisuuteesi varustaa käyttäjäsi menestymään HubSpotissa?",
        "IMPORT": "Kuinka tyytyväinen olet tietojen tuomiseen HubSpotiin?",
        "ADMIN_GOVERNANCE": "Kuinka tyytyväinen olet HubSpot-tilisi hallinnan tasoon, joka sinulla on (portaalisi pääjärjestelmänvalvojana)?",
        "SINGLE_REPORT_VIEWER": "Kuinka tyytyväinen olet siihen, että saat tietoa HubSpotin raportoinnista?",
        "REPORT_LIBRARY": "Kuinka tyytyväinen olet HubSpotin toimittamiin valmiisiin raportteihin?",
        "JOURNEY_ANALYTICS": "Kuinka tyytyväinen olet siihen, että saat tietoa HubSpotin raportoinnista?",
        "SINGLE_OBJECT_BUILDER": "Kuinka tyytyväinen olet siihen, että saat tietoa HubSpotin raportoinnista?",
        "MULTI_TOUCH_ATTRIBUTION": "Kuinka tyytyväinen olet siihen, että saat tietoa HubSpotin raportoinnista?",
        "REPORT_DASHBOARD_VIEW": "Kuinka tyytyväinen olet mahdollisuuteesi saada olennaisia tietoja HubSpotissa?",
        "PROSPECTING_MEETINGS": "Kuinka tyytyväinen olet mahdollisuuteesi kvalifioida liidejä tehokkaasti HubSpotissa?",
        "PROSPECTING_MANAGE_SEQUENCES": "Kuinka tyytyväinen olet mahdollisuuteesi kvalifioida liidejä tehokkaasti HubSpotissa?",
        "PROSPECTING_TASKS": "Kuinka tyytyväinen olet mahdollisuuteesi kvalifioida liidejä tehokkaasti HubSpotissa?",
        "SALES_EXECUTION_DEAL_PIPELINE": "Kuinka tyytyväinen olet mahdollisuuteesi sulkea kauppoja tehokkaasti HubSpotissa?",
        "SALES_EXECUTION_DEALS": "Kuinka tyytyväinen olet mahdollisuuteesi sulkea kauppoja tehokkaasti HubSpotissa?",
        "SH_DEALS_WORKSPACE": "Kuinka tyytyväinen olet mahdollisuuteesi sulkea kauppoja tehokkaasti HubSpotissa?",
        "PROSPECTING_WORKSPACE": "Kuinka tyytyväinen olet mahdollisuuteesi kvalifioida liidejä tehokkaasti HubSpotissa?",
        "CSAT_SVH_KNOWLEDGEBASE": "Kuinka tyytyväinen olet HubSpotin asiakaspalveluun?",
        "CSAT_SVH_HELPDESK": "Kuinka tyytyväinen olet HubSpotin asiakaspalveluun?",
        "CSAT_SVH_TICKET_DEFAULTS": "Kuinka tyytyväinen olet HubSpotin asiakaspalveluun?",
        "CSAT_SVH_HELPDESK_USER": "Kuinka tyytyväinen olet HubSpotin asiakaspalveluun?",
        "CSAT_SVH_LIVE_CHAT": "Kuinka tyytyväinen olet HubSpotin asiakaspalveluun?",
        "CSAT_SVH_BOT_MESSENGER": "Kuinka tyytyväinen olet HubSpotin asiakaspalveluun?",
        "CSAT_CRM_CONNECTED_APPS": "Kuinka tyytyväinen olet mahdollisuuteesi käyttää integraatioita HubSpotissa?",
        "CSAT_CRM_CREATE_CUSTOM_OBJECT": "Kuinka tyytyväinen olet yrityksesi tietojen kartoittamiseen HubSpotissa?",
        "CSAT_CRM_VIEW_CUSTOM_OBJECTS": "Kuinka tyytyväinen olet yrityksesi tietojen kartoittamiseen HubSpotissa?",
        "HELPDESK_CREATE_VIEWS": "Kuinka tyytyväinen olet asiakkaidesi tukemiseen HubSpotin avulla?",
        "PRODUCTS": "Kuinka tyytyväinen olet mahdollisuuteesi hallita yrityksesi tuotteita HubSpotissa?",
        "INVOICES": "Kuinka tyytyväinen olet kykyysi laskuttaa HubSpotilla?",
        "CMS_SEO_RECS": "Kuinka tyytyväinen olet sinulle annettuihin sisällön optimointiehdotuksiin?",
        "FILE_MANAGER_PAGE_VIEW": "Kuinka tyytyväinen olet mahdollisuuteesi hallita digitaalisia resursseja HubSpotissa?",
        "COMMERCE_SUBSCRIPTIONS": "Kuinka tyytyväinen olet tilausten hallintaan HubSpotissa?",
        "ECOSYSTEM_ACADEMY": "Kuinka tyytyväinen olet oppimiskokemukseesi HubSpot Academyssa?",
        "PAYMENT_LINKS": "Kuinka tyytyväinen olet mahdollisuuteesi hyväksyä maksuja HubSpotissa?",
        "ECOSYSTEM_APP_MARKETPLACE": "Kuinka tyytyväinen olet mahdollisuuteesi integroida muita sovelluksia HubSpotissa?",
        "ECOSYSTEM_NETWORK": "Kuinka tyytyväinen olet mahdollisuuteesi olla vuorovaikutuksessa Connect.com-yhteisön kanssa?",
        "DATASETS": "Kuinka tyytyväinen olet mahdollisuuteesi hallita tietojen laatua HubSpotissa?",
        "CTAS": "Kuinka tyytyväinen olet mahdollisuuteesi olla vuorovaikutuksessa yleisösi kanssa HubSpotissa?",
        "DEVELOPER_ACCOUNT": "Kuinka tyytyväinen olet mahdollisuuteesi rakentaa mukautettuja ratkaisuja HubSpotissa?",
        "DATA_QUALITY": "Miten tyytyväinen olet tietojen laadun hallinnointiin HubSpotissa?",
        "PLATFORM_DUPLICATE_INTERACTION": "Miten tyytyväinen olet tietojen laadun hallinnointiin HubSpotissa?",
        "CRM_DEVELOPMENT": "Kuinka tyytyväinen olet mahdollisuuteesi rakentaa mukautettuja ratkaisuja HubSpotissa?",
        "CSAT_CRM_CUSTOM_CARDS": "Kuinka tyytyväinen olet siihen, miten mukautettu tilisi on HubSpotissa?",
        "COMMERCE_HOME": "Kuinka tyytyväinen olet mahdollisuuteesi käytön aloittamiseen Commerce Hubissa?",
        "IAH_EXPERIENCE": "Kuinka tyytyväinen olet ohjekokemukseen?",
        "FORMS_VIS_EXPERIENCE": "Kuinka tyytyväinen olet mahdollisuuteesi kerätä uusia liidejä HubSpotissa?",
        "CSAT_SVH_AI_AGENT": "Kuinka tyytyväinen olet HubSpotin asiakaspalveluun?",
        "DATA_MODEL_INTERACTION": "Kuinka tyytyväinen olet mahdollisuuteesi kartoittaa yrityksesi tietoja HubSpotissa?",
        "CMS_GENERATE_PODCAST": "Kuinka tyytyväinen olet mahdollisuuteesi luoda sisältöä HubSpotissa?",
        "PLATFORM_EDIT_PERMISSIONS": "Kuinka tyytyväinen olet mahdollisuuteesi varustaa käyttäjäsi menestymään HubSpotissa?",
        "CMS_remix": "Kuinka tyytyväinen olet mahdollisuuteesi luoda sisältöä HubSpotissa?"
      },
      "wootric_recommend_target": "työkaveri tai kollega",
      "followup_thank_you": "Kiitos!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Kuinka helppoa HubSpotin toiminnan arvioiminen oli tämän kokeilun aikana?",
        "SETUP_COMPLETE": "Kuinka helppoa HubSpotin käyttöön ottaminen oli?",
        "ACADEMY": "Kuinka helppoa HubSpot-akatemian käyttäminen oli?",
        "PURCHASE_EXPERIENCE": "Kuinka helppoa sinun oli ostaa HubSpot?"
      }
    },
    "FR": {
      "followup_question": "Comment pourrions-nous améliorer votre expérience ?",
      "followup_question_nps": "Quelle est la principale raison pour laquelle vous avez donné cette note ?",
      "followup_placeholder": "Aidez-nous à nous améliorer en expliquant la note que vous avez attribué.",
      "placeholder_text": "Aidez-nous à nous améliorer en expliquant la note que vous avez attribué.",
      "send": "Envoyer",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Dans quelle mesure êtes-vous satisfait de votre compte en ligne et du processus de facturation ?",
        "WORKFLOWS": "Dans quelle mesure êtes-vous satisfait de votre capacité à automatiser vos processus dans HubSpot ?",
        "INBOX": "Quel est votre degré de satisfaction concernant la gestion des conversations dans HubSpot ?",
        "TRIAL_EXPIRATION": "Quel est votre degré de satisfaction concernant votre expérience de participation à l'essai ?",
        "TRIAL_EXPIRATION_DASHBOARD": "Quel est votre degré de satisfaction concernant votre expérience de participation à l'essai ?",
        "CMS_BRAND_SETTINGS": "Quel est votre degré de satisfaction concernant votre capacité à gérer votre marque dans HubSpot ?",
        "CMS_CONTENT_DETAIL": "Quel est votre degré de satisfaction concernant votre capacité à gérer le contenu web dans HubSpot AI ?",
        "CMS_DESIGN_MANAGER": "Quel est votre degré de satisfaction concernant votre capacité à développer votre site web dans Hubspot ?",
        "ADVANCED_BUILDER": "Quel est votre degré de satisfaction concernant votre capacité à obtenir des informations dans HubSpot ?",
        "FORECASTING": "Quel est votre degré de satisfaction concernant la gestion de votre pipeline de transactions dans HubSpot ?",
        "FORECASTING_SEPG": "Quel est votre degré de satisfaction concernant votre capacité à clôturer des transaction efficacement dans HubSpot ?",
        "SOCIAL": "Quel est votre degré de satisfaction concernant votre capacité à interagir avec votre audience en utilisant HubSpot ?",
        "MARKETING_EMAIL": "Quel est votre degré de satisfaction concernant votre capacité à entretenir votre audience en utilisant HubSpot ?",
        "FORMS": "Quel est votre degré de satisfaction concernant votre capacité à capturer de nouveaux leads en utilisant HubSpot ?",
        "CAMPAIGNS": "Quel est votre degré de satisfaction concernant votre capacité à optimiser vos efforts marketing en utilisant HubSpot ?",
        "DEAL": "Quel est votre degré de satisfaction concernant la gestion de votre pipeline de transactions dans HubSpot ?",
        "ADS": "Quel est votre degré de satisfaction concernant votre capacité à interagir avec votre public en utilisant HubSpot ?",
        "TASKS": "Quel est votre degré de satisfaction concernant la gestion de la productivité de HubSpot Sales dans HubSpot ?",
        "SEQUENCES": "Quel est votre degré de satisfaction concernant la gestion des activités de vente dans HubSpot ?",
        "QUOTES": "Quel est votre degré de satisfaction concernant votre capacité à gérer les devis dans HubSpot ?",
        "ANALYTICS": "Quel est votre degré de satisfaction concernant votre capacité à obtenir des informations dans HubSpot ?",
        "INDEX_CRM": "Quel est votre degré de satisfaction concernant les informations dont vous avez besoin pour agir dans HubSpot ?",
        "RECORD_CRM": "Quel est votre degré de satisfaction concernant les informations dont vous avez besoin pour agir dans HubSpot ?",
        "CHAT": "Quel est votre degré de satisfaction concernant la gestion du chat dans HubSpot ?",
        "DATA_SYNC": "Quel est votre degré de satisfaction concernant votre capacité à synchroniser des données dans HubSpot ?",
        "CRM_LISTS": "Quel est votre degré de satisfaction concernant votre capacité à segmenter efficacement les données dans HubSpot ?",
        "CRM_SETTINGS": "Quel votre degré de satisfaction concernant votre capacité à mapper les données de votre entreprise dans HubSpot ?",
        "PAYMENTS": "Dans quelle mesure êtes-vous satisfait(e) de la gestion des paiements dans HubSpot ?",
        "ADMIN_SETUP": "Dans quelle mesure êtes-vous satisfait de votre capacité à aider vos utilisateurs à atteindre leurs objectifs dans HubSpot ?",
        "IMPORT": "Quel est votre degré de satisfaction concernant l'import de données dans HubSpot ?",
        "ADMIN_GOVERNANCE": "Dans quelle mesure êtes-vous satisfait du niveau de contrôle dont vous disposez sur votre compte HubSpot (en tant que super administrateur de votre portail) ?",
        "SINGLE_REPORT_VIEWER": "Quel est votre degré de satisfaction concernant l'obtention d'informations via des rapports HubSpot ?",
        "REPORT_LIBRARY": "Quel est votre degré de satisfaction concernant les rapports prêts à l'emploi fournis par HubSpot ?",
        "JOURNEY_ANALYTICS": "Quel est votre degré de satisfaction concernant l'obtention d'informations via des rapports HubSpot ?",
        "SINGLE_OBJECT_BUILDER": "Quel est votre degré de satisfaction concernant l'obtention d'informations via des rapports HubSpot ?",
        "MULTI_TOUCH_ATTRIBUTION": "Quel est votre degré de satisfaction concernant l'obtention d'informations via des rapports HubSpot ?",
        "REPORT_DASHBOARD_VIEW": "Quel est votre degré de satisfaction concernant votre capacité à obtenir des informations dans HubSpot ?",
        "PROSPECTING_MEETINGS": "Quel est votre degré de satisfaction concernant votre capacité à qualifier des transactions efficacement dans HubSpot ?",
        "PROSPECTING_MANAGE_SEQUENCES": "Quel est votre degré de satisfaction concernant votre capacité à qualifier des transactions efficacement dans HubSpot ?",
        "PROSPECTING_TASKS": "Quel est votre degré de satisfaction concernant votre capacité à qualifier des transactions efficacement dans HubSpot ?",
        "SALES_EXECUTION_DEAL_PIPELINE": "Quel est votre degré de satisfaction concernant votre capacité à clôturer des transaction efficacement dans HubSpot ?",
        "SALES_EXECUTION_DEALS": "Quel est votre degré de satisfaction concernant votre capacité à clôturer des transaction efficacement dans HubSpot ?",
        "SH_DEALS_WORKSPACE": "Quel est votre degré de satisfaction concernant votre capacité à clôturer des transaction efficacement dans HubSpot ?",
        "PROSPECTING_WORKSPACE": "Quel est votre degré de satisfaction concernant votre capacité à qualifier des transactions efficacement dans HubSpot ?",
        "CSAT_SVH_KNOWLEDGEBASE": "Dans quelle mesure êtes-vous satisfait(e) de l'utilisation de HubSpot pour le support client ?",
        "CSAT_SVH_HELPDESK": "Dans quelle mesure êtes-vous satisfait(e) de l'utilisation de HubSpot pour le support client ?",
        "CSAT_SVH_TICKET_DEFAULTS": "Dans quelle mesure êtes-vous satisfait(e) de l'utilisation de HubSpot pour le support client ?",
        "CSAT_SVH_HELPDESK_USER": "Dans quelle mesure êtes-vous satisfait(e) de l'utilisation de HubSpot pour le support client ?",
        "CSAT_SVH_LIVE_CHAT": "Dans quelle mesure êtes-vous satisfait(e) de l'utilisation de HubSpot pour le support client ?",
        "CSAT_SVH_BOT_MESSENGER": "Dans quelle mesure êtes-vous satisfait(e) de l'utilisation de HubSpot pour le support client ?",
        "CSAT_CRM_CONNECTED_APPS": "Quel est votre degré de satisfaction concernant votre capacité à utiliser les intégrations au sein de HubSpot ?",
        "CSAT_CRM_CREATE_CUSTOM_OBJECT": "Quel est votre degré de satisfaction concernant le mappage les données de votre entreprise vers HubSpot ?",
        "CSAT_CRM_VIEW_CUSTOM_OBJECTS": "Quel est votre degré de satisfaction concernant le mappage les données de votre entreprise vers HubSpot ?",
        "HELPDESK_CREATE_VIEWS": "Dans quelle mesure êtes-vous satisfait de soutenir vos clients avec HubSpot ?",
        "PRODUCTS": "Quel est votre degré de satisfaction concernant votre capacité à gérer les produits de votre entreprise dans HubSpot ?",
        "INVOICES": "Quel est votre degré de satisfaction concernant votre capacité à facturer en utilisant HubSpot ?",
        "CMS_SEO_RECS": "Comment trouvez-vous les suggestions d'optimisation de contenu qui vous ont été données ?",
        "FILE_MANAGER_PAGE_VIEW": "Quel est votre degré de satisfaction concernant votre capacité à gérer les ressources numériques dans HubSpot ?",
        "COMMERCE_SUBSCRIPTIONS": "Quel est votre degré de satisfaction concernant la gestion des abonnements dans HubSpot ?",
        "ECOSYSTEM_ACADEMY": "Quel est votre degré de satisfaction concernant votre expérience d'apprentissage avec HubSpot Academy ?",
        "PAYMENT_LINKS": "Quel est votre degré de satisfaction concernant votre capacité à accepter des paiements dans HubSpot AI ?",
        "ECOSYSTEM_APP_MARKETPLACE": "Quel est votre degré de satisfaction quant à votre capacité à intégrer d'autres applications à HubSpot ?",
        "ECOSYSTEM_NETWORK": "Quel est votre degré de satisfaction concernant votre capacité à interagir avec la communauté Connect.com ?",
        "DATASETS": "Quel est votre degré de satisfaction concernant votre capacité à gérer la qualité des données dans HubSpot ?",
        "CTAS": "Quel est votre degré de satisfaction concernant votre capacité à interagir avec votre audience en utilisant HubSpot ?",
        "DEVELOPER_ACCOUNT": "Quel est votre degré de satisfaction concernant votre capacité à créer des solutions personnalisées sur HubSpot ?",
        "DATA_QUALITY": "Quel est votre degré de satisfaction concernant la gestion de la qualité des données dans HubSpot ?",
        "PLATFORM_DUPLICATE_INTERACTION": "Quel est votre degré de satisfaction concernant la gestion de la qualité des données dans HubSpot ?",
        "CRM_DEVELOPMENT": "Quel est votre degré de satisfaction concernant votre capacité à créer des solutions personnalisées sur HubSpot ?",
        "CSAT_CRM_CUSTOM_CARDS": "Quel est votre degré de satisfaction concernant la personnalisation de votre compte dans HubSpot ?",
        "COMMERCE_HOME": "Quel est votre degré de satisfaction concernant votre capacité à démarrer avec le Commerce Hub ?",
        "IAH_EXPERIENCE": "Quel est votre degré de satisfaction concernant votre expérience d'aide ?",
        "FORMS_VIS_EXPERIENCE": "Quel est votre degré de satisfaction concernant votre capacité à capturer de nouveaux leads en utilisant HubSpot ?",
        "CSAT_SVH_AI_AGENT": "Dans quelle mesure êtes-vous satisfait(e) de l'utilisation de HubSpot pour le support client ?",
        "DATA_MODEL_INTERACTION": "Quel votre degré de satisfaction concernant votre capacité à mapper les données de votre entreprise dans HubSpot ?",
        "CMS_GENERATE_PODCAST": "Quel est votre degré de satisfaction concernant votre capacité à créer du contenu dans HubSpot ?",
        "PLATFORM_EDIT_PERMISSIONS": "Dans quelle mesure êtes-vous satisfait de votre capacité à aider vos utilisateurs à atteindre leurs objectifs dans HubSpot ?",
        "CMS_remix": "Quel est votre degré de satisfaction concernant votre capacité à créer du contenu dans HubSpot ?"
      },
      "wootric_recommend_target": "un collègue",
      "followup_thank_you": "Merci !",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Dans quelle mesure est-il facile pour vous d'évaluer les fonctionnalités de HubSpot pendant cet essai ? ",
        "SETUP_COMPLETE": "Dans quelle mesure la configuration des paramètres avec HubSpot est-elle facile ?",
        "ACADEMY": "Dans quelle mesure est-il facile pour vous d'utiliser HubSpot Academy ?",
        "PURCHASE_EXPERIENCE": "Dans quelle mesure l'achat de HubSpot est-il facile ?"
      }
    },
    "IT": {
      "followup_question": "Cosa potremmo fare per migliorare la tua esperienza?",
      "followup_question_nps": "Qual è il motivo più importante del punteggio?",
      "followup_placeholder": "Aiutaci a migliorare spiegandoci il tuo punteggio.",
      "placeholder_text": "Aiutaci a migliorare spiegandoci il tuo punteggio.",
      "send": "Invia",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Quanto sei soddisfatto del tuo account e della tua esperienza di fatturazione online?",
        "WORKFLOWS": "Quanto ti soddisfa la possibilità di automatizzare i tuoi processi in HubSpot?",
        "INBOX": "Quanto sei soddisfatto della gestione delle conversazioni in HubSpot?",
        "TRIAL_EXPIRATION": "Quanto sei soddisfatto della tua esperienza di prova complessiva?",
        "TRIAL_EXPIRATION_DASHBOARD": "Quanto sei soddisfatto della tua esperienza di prova complessiva?",
        "CMS_BRAND_SETTINGS": "Quanto ti soddisfa la possibilità di gestire il tuo brand in HubSpot?",
        "CMS_CONTENT_DETAIL": "Quanto ti soddisfano le funzionalità di gestione dei contenuti web in HubSpot?",
        "CMS_DESIGN_MANAGER": "Quanto ti soddisfano le funzionalità di sviluppo del tuo sito web in HubSpot?",
        "ADVANCED_BUILDER": "Quanto ti soddisfa la possibilità di ricevere informazioni dettagliate in HubSpot?",
        "FORECASTING": "Quanto sei soddisfatto della gestione della pipeline delle trattative in HubSpot?",
        "FORECASTING_SEPG": "Quanto ti soddisfa la possibilità di chiudere in modo efficace le trattative in HubSpot?",
        "SOCIAL": "Quanto ti soddisfa la possibilità di interagire con il tuo pubblico usando HubSpot?",
        "MARKETING_EMAIL": "Quanto ti soddisfa la possibilità di fidelizzare il tuo pubblico usando HubSpot?",
        "FORMS": "Quanto ti soddisfa la possibilità di acquisire nuovi lead usando HubSpot?",
        "CAMPAIGNS": "Quanto ti soddisfa la possibilità di ottimizzare le operazioni di marketing usando HubSpot?",
        "DEAL": "Quanto sei soddisfatto della gestione della pipeline delle trattative in HubSpot?",
        "ADS": "Quanto ti soddisfa la possibilità di interagire con il tuo pubblico usando HubSpot?",
        "TASKS": "Quanto sei soddisfatto della gestione della produttività delle vendite in HubSpot?",
        "SEQUENCES": "Quanto sei soddisfatto della gestione dell'estensione delle vendite in HubSpot?",
        "QUOTES": "Qual è il tuo livello di soddisfazione delle funzionalità di gestione dei preventivi in HubSpot?",
        "ANALYTICS": "Quanto ti soddisfa la possibilità di ricevere informazioni dettagliate in HubSpot?",
        "INDEX_CRM": "Quanto ti soddisfa la possibilità di disporre delle informazioni necessarie per intraprendere azioni in HubSpot?",
        "RECORD_CRM": "Quanto ti soddisfa la possibilità di disporre delle informazioni necessarie per intraprendere azioni in HubSpot?",
        "CHAT": "Quanto sei soddisfatto della gestione della chat in HubSpot?",
        "DATA_SYNC": "Quanto sei soddisfatto della possibilità di sincronizzare i dati in HubSpot?",
        "CRM_LISTS": "Qual è il tuo livello di soddisfazione in merito alla possibilità di segmentare i dati in modo efficace in HubSpot?",
        "CRM_SETTINGS": "Quanto ti soddisfa la possibilità di mappare i dati della tua azienda in HubSpot?",
        "PAYMENTS": "Qual è il tuo livello di soddisfazione della gestione dei pagamenti in HubSpot?",
        "ADMIN_SETUP": "Quanto ti soddisfa la possibilità di spianare la strada dei tuoi utenti verso il successo in HubSpot?",
        "IMPORT": "Quanto ti soddisfa la funzionalità di importazione dati in HubSpot?",
        "ADMIN_GOVERNANCE": "Quanto sei soddisfatto del livello di controllo sull'account HubSpot di cui disponi (in qualità di super amministratore per il tuo portale)?",
        "SINGLE_REPORT_VIEWER": "Qual è il tuo livello di soddisfazione delle informazioni acquisite dai rapporti HubSpot?",
        "REPORT_LIBRARY": "Qual è il tuo livello di soddisfazione dei rapporti predefiniti forniti da HubSpot?",
        "JOURNEY_ANALYTICS": "Qual è il tuo livello di soddisfazione delle informazioni acquisite dai rapporti HubSpot?",
        "SINGLE_OBJECT_BUILDER": "Qual è il tuo livello di soddisfazione delle informazioni acquisite dai rapporti HubSpot?",
        "MULTI_TOUCH_ATTRIBUTION": "Qual è il tuo livello di soddisfazione delle informazioni acquisite dai rapporti HubSpot?",
        "REPORT_DASHBOARD_VIEW": "Quanto ti soddisfa la possibilità di ricevere informazioni dettagliate in HubSpot?",
        "PROSPECTING_MEETINGS": "Quanto ti soddisfa la possibilità di creare lead qualificati in modo efficace in HubSpot?",
        "PROSPECTING_MANAGE_SEQUENCES": "Quanto ti soddisfa la possibilità di creare lead qualificati in modo efficace in HubSpot?",
        "PROSPECTING_TASKS": "Quanto ti soddisfa la possibilità di creare lead qualificati in modo efficace in HubSpot?",
        "SALES_EXECUTION_DEAL_PIPELINE": "Quanto ti soddisfa la possibilità di chiudere in modo efficace le trattative in HubSpot?",
        "SALES_EXECUTION_DEALS": "Quanto ti soddisfa la possibilità di chiudere in modo efficace le trattative in HubSpot?",
        "SH_DEALS_WORKSPACE": "Quanto ti soddisfa la possibilità di chiudere in modo efficace le trattative in HubSpot?",
        "PROSPECTING_WORKSPACE": "Quanto ti soddisfa la possibilità di creare lead qualificati in modo efficace in HubSpot?",
        "CSAT_SVH_KNOWLEDGEBASE": "Qual è il tuo livello di soddisfazione in merito all'utilizzo di HubSpot per l'assistenza clienti?",
        "CSAT_SVH_HELPDESK": "Qual è il tuo livello di soddisfazione in merito all'utilizzo di HubSpot per l'assistenza clienti?",
        "CSAT_SVH_TICKET_DEFAULTS": "Qual è il tuo livello di soddisfazione in merito all'utilizzo di HubSpot per l'assistenza clienti?",
        "CSAT_SVH_HELPDESK_USER": "Qual è il tuo livello di soddisfazione in merito all'utilizzo di HubSpot per l'assistenza clienti?",
        "CSAT_SVH_LIVE_CHAT": "Qual è il tuo livello di soddisfazione in merito all'utilizzo di HubSpot per l'assistenza clienti?",
        "CSAT_SVH_BOT_MESSENGER": "Qual è il tuo livello di soddisfazione in merito all'utilizzo di HubSpot per l'assistenza clienti?",
        "CSAT_CRM_CONNECTED_APPS": "Quanto ti soddisfa la tua capacità di usare le integrazioni in HubSpot?",
        "CSAT_CRM_CREATE_CUSTOM_OBJECT": "Quanto ti soddisfa la mappatura dei dati della tua azienda in HubSpot?",
        "CSAT_CRM_VIEW_CUSTOM_OBJECTS": "Quanto ti soddisfa la mappatura dei dati della tua azienda in HubSpot?",
        "HELPDESK_CREATE_VIEWS": "Quanto ti soddisfa il supporto che fornisci ai clienti con HubSpot?",
        "PRODUCTS": "Quanto ti soddisfa la possibilità di gestire i tuoi prodotti aziendali in HubSpot?",
        "INVOICES": "Quanto ti soddisfano le funzionalità di fatturazione di HubSpot?",
        "CMS_SEO_RECS": "Quanto ti soddisfano i suggerimenti per l'ottimizzazione dei contenuti che ti sono stati forniti?",
        "FILE_MANAGER_PAGE_VIEW": "Quanto ti soddisfa la possibilità di gestire gli asset digitali in HubSpot?",
        "COMMERCE_SUBSCRIPTIONS": "Quanto sei soddisfatto della gestione degli abbonamenti in HubSpot?",
        "ECOSYSTEM_ACADEMY": "Quanto ti soddisfa l'esperienza di apprendimento con HubSpot Academy?",
        "PAYMENT_LINKS": "Quanto ti soddisfa la possibilità di accettare i pagamenti in HubSpot?",
        "ECOSYSTEM_APP_MARKETPLACE": "Qual è il tuo livello di soddisfazione in merito alla possibilità di integrare altre app con HubSpot?",
        "ECOSYSTEM_NETWORK": "Quanto ti soddisfa la possibilità di interagire con la community di Connect.com?",
        "DATASETS": "Quanto ti soddisfa la possibilità di gestire la qualità dei dati in HubSpot?",
        "CTAS": "Quanto ti soddisfa la possibilità di interagire con il tuo pubblico usando HubSpot?",
        "DEVELOPER_ACCOUNT": "Quanto ti soddisfa la possibilità di creare soluzioni personalizzate in HubSpot?",
        "DATA_QUALITY": "Quanto ti soddisfa la gestione della qualità dei dati in HubSpot?",
        "PLATFORM_DUPLICATE_INTERACTION": "Quanto ti soddisfa la gestione della qualità dei dati in HubSpot?",
        "CRM_DEVELOPMENT": "Quanto ti soddisfa la possibilità di creare soluzioni personalizzate in HubSpot?",
        "CSAT_CRM_CUSTOM_CARDS": "Quanto ti soddisfa il modo in cui hai personalizzato il tuo account in HubSpot?",
        "COMMERCE_HOME": "Quanto ti soddisfa la possibilità di iniziare a usare Commerce Hub?",
        "IAH_EXPERIENCE": "Quanto ti soddisfa l'esperienza della Guida?",
        "FORMS_VIS_EXPERIENCE": "Quanto ti soddisfa la possibilità di acquisire nuovi lead usando HubSpot?",
        "CSAT_SVH_AI_AGENT": "Qual è il tuo livello di soddisfazione in merito all'utilizzo di HubSpot per l'assistenza clienti?",
        "DATA_MODEL_INTERACTION": "Quanto ti soddisfa la possibilità di mappare i dati della tua azienda in HubSpot?",
        "CMS_GENERATE_PODCAST": "Quanto ti soddisfa la possibilità di creare contenuti in HubSpot?",
        "PLATFORM_EDIT_PERMISSIONS": "Quanto ti soddisfa la possibilità di spianare la strada dei tuoi utenti verso il successo in HubSpot?",
        "CMS_remix": "Quanto ti soddisfa la possibilità di creare contenuti in HubSpot?"
      },
      "wootric_recommend_target": "un collaboratore o un collega",
      "followup_thank_you": "Grazie.",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Quanto è stato facile valutare la funzionalità di HubSpot durante la prova?",
        "SETUP_COMPLETE": "Quanto è stato facile configurare HubSpot?",
        "ACADEMY": "Quanto è stato facile usare Academy?",
        "PURCHASE_EXPERIENCE": "Quanto è stato facile acquistare HubSpot?"
      }
    },
    "JA": {
      "followup_question": "改善に向けてお気付きの点がございましたら、お聞かせください。",
      "followup_question_nps": "この評価の最も重要な理由を教えてください。",
      "followup_placeholder": "いただいた評価について理由をお聞かせください。今後の参考にさせていただきます。",
      "placeholder_text": "いただいた評価について理由をお聞かせください。今後の参考にさせていただきます。",
      "send": "送信",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "オンラインの「アカウントと請求」機能にご満足いただけましたか？",
        "WORKFLOWS": "HubSpot上でプロセスを自動化する機能にご満足いただけましたか？",
        "INBOX": "HubSpotでのコミュニケーションの管理にご満足いただけましたか？",
        "TRIAL_EXPIRATION": "全体的なトライアル体験にご満足いただけましたか？",
        "TRIAL_EXPIRATION_DASHBOARD": "全体的なトライアル体験にご満足いただけましたか？",
        "CMS_BRAND_SETTINGS": "HubSpot上で自社のブランドを管理する機能にご満足いただけましたか？",
        "CMS_CONTENT_DETAIL": "HubSpot上でウェブコンテンツを管理する機能にご満足いただけましたか？",
        "CMS_DESIGN_MANAGER": "HubSpot上でウェブサイトを開発する機能にご満足いただけましたか？",
        "ADVANCED_BUILDER": "HubSpot上で分析を表示する機能にご満足いただけましたか？",
        "FORECASTING": "HubSpotでの取引パイプラインの管理にご満足いただけましたか？",
        "FORECASTING_SEPG": "HubSpot上で取引を効果的に成立させる機能にご満足いただけましたか？",
        "SOCIAL": "HubSpotを使用してオーディエンスとの信頼関係を築く機能にご満足いただけましたか？",
        "MARKETING_EMAIL": "HubSpotを使用してオーディエンスの購買意欲を醸成する機能にご満足いただけましたか？",
        "FORMS": "HubSpotを使用して新しいリードの情報を収集する機能にご満足いただけましたか？",
        "CAMPAIGNS": "HubSpotを使用してマーケティング活動を最適化する機能にご満足いただけましたか？",
        "DEAL": "HubSpotでの取引パイプラインの管理にご満足いただけましたか？",
        "ADS": "HubSpotを使用してオーディエンスとの信頼関係を築く機能にご満足いただけましたか？",
        "TASKS": "HubSpotでの営業の生産性管理にご満足いただけましたか？",
        "SEQUENCES": "HubSpotでの営業アプローチの管理にご満足いただけましたか？",
        "QUOTES": "HubSpot上で見積もりを管理する機能にご満足いただけましたか？",
        "ANALYTICS": "HubSpot上で分析を表示する機能にご満足いただけましたか？",
        "INDEX_CRM": "HubSpot上でのアクションを実行するために必要な情報の取得にご満足いただけましたか？",
        "RECORD_CRM": "HubSpot上でのアクションを実行するために必要な情報の取得にご満足いただけましたか？",
        "CHAT": "HubSpotでのチャットの管理にご満足いただけましたか？",
        "DATA_SYNC": "HubSpot上でデータを同期する機能にご満足いただけましたか？",
        "CRM_LISTS": "HubSpot上でデータを効果的にセグメント化する機能にご満足いただけましたか？",
        "CRM_SETTINGS": "HubSpot上で貴社のデータを対応付ける機能にご満足いただけましたか？",
        "PAYMENTS": "HubSpotでの支払い管理にご満足いただけましたか？",
        "ADMIN_SETUP": "HubSpot上で成功に向けてユーザーの準備を整える機能にご満足いただけましたか？",
        "IMPORT": "データをHubSpotにインポートする機能にご満足いただけましたか？",
        "ADMIN_GOVERNANCE": "HubSpotアカウントに対する制御レベルにご満足いただけましたか（ポータルのスーパー管理者として）？",
        "SINGLE_REPORT_VIEWER": "HubSpotのレポート機能による分析にご満足いただけましたか？",
        "REPORT_LIBRARY": "HubSpotが提供する設定不要のレポートにご満足いただけましたか？",
        "JOURNEY_ANALYTICS": "HubSpotのレポート機能による分析にご満足いただけましたか？",
        "SINGLE_OBJECT_BUILDER": "HubSpotのレポート機能による分析にご満足いただけましたか？",
        "MULTI_TOUCH_ATTRIBUTION": "HubSpotのレポート機能による分析にご満足いただけましたか？",
        "REPORT_DASHBOARD_VIEW": "HubSpot上で分析を表示する機能にご満足いただけましたか？",
        "PROSPECTING_MEETINGS": "HubSpot上で効果的にリードの見込みを判定する機能にご満足いただけましたか？",
        "PROSPECTING_MANAGE_SEQUENCES": "HubSpot上で効果的にリードの見込みを判定する機能にご満足いただけましたか？",
        "PROSPECTING_TASKS": "HubSpot上で効果的にリードの見込みを判定する機能にご満足いただけましたか？",
        "SALES_EXECUTION_DEAL_PIPELINE": "HubSpot上で取引を効果的に成立させる機能にご満足いただけましたか？",
        "SALES_EXECUTION_DEALS": "HubSpot上で取引を効果的に成立させる機能にご満足いただけましたか？",
        "SH_DEALS_WORKSPACE": "HubSpot上で取引を効果的に成立させる機能にご満足いただけましたか？",
        "PROSPECTING_WORKSPACE": "HubSpot上で効果的にリードの見込みを判定する機能にご満足いただけましたか？",
        "CSAT_SVH_KNOWLEDGEBASE": "カスタマーサポートに関するHubSpotの機能にご満足いただけましたか？",
        "CSAT_SVH_HELPDESK": "カスタマーサポートに関するHubSpotの機能にご満足いただけましたか？",
        "CSAT_SVH_TICKET_DEFAULTS": "カスタマーサポートに関するHubSpotの機能にご満足いただけましたか？",
        "CSAT_SVH_HELPDESK_USER": "カスタマーサポートに関するHubSpotの機能にご満足いただけましたか？",
        "CSAT_SVH_LIVE_CHAT": "カスタマーサポートに関するHubSpotの機能にご満足いただけましたか？",
        "CSAT_SVH_BOT_MESSENGER": "カスタマーサポートに関するHubSpotの機能にご満足いただけましたか？",
        "CSAT_CRM_CONNECTED_APPS": "HubSpot内で連携を使用する機能にご満足いただけましたか？",
        "CSAT_CRM_CREATE_CUSTOM_OBJECT": "HubSpotに貴社のデータを対応付ける機能にご満足いただけましたか？",
        "CSAT_CRM_VIEW_CUSTOM_OBJECTS": "HubSpotに貴社のデータを対応付ける機能にご満足いただけましたか？",
        "HELPDESK_CREATE_VIEWS": "HubSpotを使用して顧客をサポートする機能にご満足いただけましたか？",
        "PRODUCTS": "HubSpot上で自社の製品を管理する機能にご満足いただけましたか？",
        "INVOICES": "HubSpotを使用して請求書を作成する機能にご満足いただけましたか？",
        "CMS_SEO_RECS": "コンテンツの最適化に関する提案にご満足いただけましたか？",
        "FILE_MANAGER_PAGE_VIEW": "HubSpot上でデジタルアセット（コンテンツ）を管理する機能にご満足いただけましたか？",
        "COMMERCE_SUBSCRIPTIONS": "HubSpotでのサブスクリプションの管理にご満足いただけましたか？",
        "ECOSYSTEM_ACADEMY": "HubSpotアカデミーでの学習にご満足いただけましたか？",
        "PAYMENT_LINKS": "HubSpot上で支払いを受け入れる機能にご満足いただけましたか？",
        "ECOSYSTEM_APP_MARKETPLACE": "他のアプリをHubSpotと連携する機能にご満足いただけましたか？",
        "ECOSYSTEM_NETWORK": "Connect.comコミュニティーでのつながりを築く機能にご満足いただけましたか？",
        "DATASETS": "HubSpot上でデータ品質を管理する機能にご満足いただけましたか？",
        "CTAS": "HubSpotを使用してオーディエンスとの信頼関係を築く機能にご満足いただけましたか？",
        "DEVELOPER_ACCOUNT": "HubSpot上でカスタムソリューションを作成する機能にご満足いただけましたか？",
        "DATA_QUALITY": "HubSpotでのデータ品質の管理にご満足いただけましたか？",
        "PLATFORM_DUPLICATE_INTERACTION": "HubSpotでのデータ品質の管理にご満足いただけましたか？",
        "CRM_DEVELOPMENT": "HubSpot上でカスタムソリューションを作成する機能にご満足いただけましたか？",
        "CSAT_CRM_CUSTOM_CARDS": "HubSpot上でのアカウントのカスタマイズにご満足いただけましたか？",
        "COMMERCE_HOME": "Commerce Hubを使い始める流れにご満足いただけましたか？",
        "IAH_EXPERIENCE": "ヘルプ環境にご満足いただけましたか？",
        "FORMS_VIS_EXPERIENCE": "HubSpotを使用して新しいリードの情報を収集する機能にご満足いただけましたか？",
        "CSAT_SVH_AI_AGENT": "カスタマーサポートに関するHubSpotの機能にご満足いただけましたか？",
        "DATA_MODEL_INTERACTION": "HubSpot上で貴社のデータを対応付ける機能にご満足いただけましたか？",
        "CMS_GENERATE_PODCAST": "HubSpot上でコンテンツを作成する機能にご満足いただけましたか？",
        "PLATFORM_EDIT_PERMISSIONS": "HubSpot上で成功に向けてユーザーの準備を整える機能にご満足いただけましたか？",
        "CMS_remix": "HubSpot上でコンテンツを作成する機能にご満足いただけましたか？"
      },
      "wootric_recommend_target": "同僚や友人に",
      "followup_thank_you": "ありがとうございます。",
      "ces_questions": {
        "TRIAL_EXPIRATION": "今回のトライアル期間中、お客さまにとってHubSpot機能の評価は簡単でしたか？",
        "SETUP_COMPLETE": "お客さまにとって、HubSpotの初期設定は簡単でしたか？",
        "ACADEMY": "お客さまにとって、アカデミーの利用は簡単でしたか？",
        "PURCHASE_EXPERIENCE": "お客さまにとって、HubSpotのご購入は簡単でしたか？"
      }
    },
    "NL": {
      "followup_question": "Hoe kunnen we je een betere ervaring bieden?",
      "followup_question_nps": "Wat is de belangrijkste reden voor je score?",
      "followup_placeholder": "Help ons het product te verbeteren door je score toe te lichten.",
      "placeholder_text": "Help ons het product te verbeteren door je score toe te lichten.",
      "send": "Verzenden",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Hoe tevreden ben je met je online-account en de facturering?",
        "WORKFLOWS": "Hoe tevreden ben je over de mogelijkheid om je processen in HubSpot te automatiseren?",
        "INBOX": "Hoe tevreden ben je met je met het beheren van gesprekken in HubSpot?",
        "TRIAL_EXPIRATION": "Hoe tevreden ben je met je algehele trial-ervaring?",
        "TRIAL_EXPIRATION_DASHBOARD": "Hoe tevreden ben je met je algehele trial-ervaring?",
        "CMS_BRAND_SETTINGS": "Hoe tevreden ben je over je vermogen om je merk te beheren in HubSpot?",
        "CMS_CONTENT_DETAIL": "Hoe tevreden ben je over je vermogen om webinhoud te beheren in HubSpot?",
        "CMS_DESIGN_MANAGER": "Hoe tevreden ben je over de mogelijkheid om je website te ontwikkelen in HubSpot?",
        "ADVANCED_BUILDER": "Hoe tevreden ben je over je vermogen om inzichten op te halen in HubSpot?",
        "FORECASTING": "Hoe tevreden ben je met je met het beheren van je dealpijplijn in HubSpot?",
        "FORECASTING_SEPG": "Hoe tevreden ben je over je vermogen om effectief deals te sluiten in HubSpot?",
        "SOCIAL": "Hoe tevreden ben je over je vermogen om contact te leggen met je doelgroep met HubSpot?",
        "MARKETING_EMAIL": "Hoe tevreden ben jij over je vermogen om je doelgroep warm te houden met HubSpot?",
        "FORMS": "Hoe tevreden ben je over je vermogen om nieuwe leads vast te leggen met HubSpot?",
        "CAMPAIGNS": "Hoe tevreden ben je over je vermogen om je marketinginspanningen te optimaliseren met HubSpot?",
        "DEAL": "Hoe tevreden ben je met je met het beheren van je dealpijplijn in HubSpot?",
        "ADS": "Hoe tevreden ben je over je vermogen om contact te leggen met je doelgroep met HubSpot?",
        "TASKS": "Hoe tevreden ben je met je met het beheren van je verkoopproductiviteit in HubSpot?",
        "SEQUENCES": "Hoe tevreden ben je met je met het beheren van je verkoopoutreach in HubSpot?",
        "QUOTES": "Hoe tevreden ben je over je vermogen om prijsopgaven te beheren in HubSpot?",
        "ANALYTICS": "Hoe tevreden ben je over je vermogen om inzichten op te halen in HubSpot?",
        "INDEX_CRM": "Hoe tevreden ben je met de informatie die je nodig hebt om actie te ondernemen in HubSpot?",
        "RECORD_CRM": "Hoe tevreden ben je met de informatie die je nodig hebt om actie te ondernemen in HubSpot?",
        "CHAT": "Hoe tevreden ben je met je met het beheren van chat in HubSpot?",
        "DATA_SYNC": "Hoe tevreden ben je met je met de mogelijkheid om gegevens te synchroniseren in HubSpot?",
        "CRM_LISTS": "Hoe tevreden ben je met je met de mogelijkheid om gegevens effectief te segmenteren in HubSpot?",
        "CRM_SETTINGS": "Hoe tevreden ben je over de mogelijkheid om de gegevens van je bedrijf in HubSpot toe te wijzen?",
        "PAYMENTS": "Hoe tevreden ben je met het beheren van betalingen in HubSpot?",
        "ADMIN_SETUP": "Hoe tevreden ben je over de mogelijkheid om je gebruikers voor te bereiden op succes in HubSpot?",
        "IMPORT": "Hoe tevreden ben je over het importeren van gegevens in HubSpot?",
        "ADMIN_GOVERNANCE": "Hoe tevreden ben je met de controle die je hebt over je HubSpot-account (als superbeheerder voor je portaal)?",
        "SINGLE_REPORT_VIEWER": "Hoe tevreden ben je met de inzichten in HubSpot-rapporten?",
        "REPORT_LIBRARY": "Hoe tevreden ben je met de kant-en-klare rapporten van HubSpot?",
        "JOURNEY_ANALYTICS": "Hoe tevreden ben je met de inzichten in HubSpot-rapporten?",
        "SINGLE_OBJECT_BUILDER": "Hoe tevreden ben je met de inzichten in HubSpot-rapporten?",
        "MULTI_TOUCH_ATTRIBUTION": "Hoe tevreden ben je met de inzichten in HubSpot-rapporten?",
        "REPORT_DASHBOARD_VIEW": "Hoe tevreden ben je over je vermogen om inzichten op te halen in HubSpot?",
        "PROSPECTING_MEETINGS": "Hoe tevreden ben je over je vermogen om leads effectief te kwalificeren in HubSpot?",
        "PROSPECTING_MANAGE_SEQUENCES": "Hoe tevreden ben je over je vermogen om leads effectief te kwalificeren in HubSpot?",
        "PROSPECTING_TASKS": "Hoe tevreden ben je over je vermogen om leads effectief te kwalificeren in HubSpot?",
        "SALES_EXECUTION_DEAL_PIPELINE": "Hoe tevreden ben je over je vermogen om effectief deals te sluiten in HubSpot?",
        "SALES_EXECUTION_DEALS": "Hoe tevreden ben je over je vermogen om effectief deals te sluiten in HubSpot?",
        "SH_DEALS_WORKSPACE": "Hoe tevreden ben je over je vermogen om effectief deals te sluiten in HubSpot?",
        "PROSPECTING_WORKSPACE": "Hoe tevreden ben je over je vermogen om leads effectief te kwalificeren in HubSpot?",
        "CSAT_SVH_KNOWLEDGEBASE": "Hoe tevreden ben je met de klantondersteuning van HubSpot?",
        "CSAT_SVH_HELPDESK": "Hoe tevreden ben je met de klantondersteuning van HubSpot?",
        "CSAT_SVH_TICKET_DEFAULTS": "Hoe tevreden ben je met de klantondersteuning van HubSpot?",
        "CSAT_SVH_HELPDESK_USER": "Hoe tevreden ben je met de klantondersteuning van HubSpot?",
        "CSAT_SVH_LIVE_CHAT": "Hoe tevreden ben je met de klantondersteuning van HubSpot?",
        "CSAT_SVH_BOT_MESSENGER": "Hoe tevreden ben je met de klantondersteuning van HubSpot?",
        "CSAT_CRM_CONNECTED_APPS": "Hoe tevreden ben je over je vermogen om integraties te gebruiken in HubSpot?",
        "CSAT_CRM_CREATE_CUSTOM_OBJECT": "Hoe tevreden ben je over het toewijzen van de gegevens van je bedrijf aan HubSpot?",
        "CSAT_CRM_VIEW_CUSTOM_OBJECTS": "Hoe tevreden ben je over het toewijzen van de gegevens van je bedrijf aan HubSpot?",
        "HELPDESK_CREATE_VIEWS": "Hoe tevreden ben je over de ondersteuning van je klanten met HubSpot?",
        "PRODUCTS": "Hoe tevreden ben je over de mogelijkheid om de producten van je bedrijf te beheren in HubSpot?",
        "INVOICES": "Hoe tevreden ben je met je met de mogelijkheid om facturen te maken in HubSpot?",
        "CMS_SEO_RECS": "Hoe tevreden ben je met de suggesties voor inhoudoptimalisatie die je hebt gekregen?",
        "FILE_MANAGER_PAGE_VIEW": "Hoe tevreden ben je over je vermogen om digitale assets te beheren in HubSpot?",
        "COMMERCE_SUBSCRIPTIONS": "Hoe tevreden ben je over het beheren van abonnementen in HubSpot?",
        "ECOSYSTEM_ACADEMY": "Hoe tevreden ben je over je leerervaring met HubSpot Academy?",
        "PAYMENT_LINKS": "Hoe tevreden ben je over de mogelijkheid om betalingen te accepteren in HubSpot?",
        "ECOSYSTEM_APP_MARKETPLACE": "Hoe tevreden ben je over de mogelijkheid om andere apps te integreren met HubSpot?",
        "ECOSYSTEM_NETWORK": "Hoe tevreden ben je over je vermogen om contact te leggen met de  community van Connect.com?",
        "DATASETS": "Hoe tevreden ben je over je vermogen om gegevenskwaliteit te beheren in HubSpot?",
        "CTAS": "Hoe tevreden ben je over je vermogen om contact te leggen met je doelgroep met HubSpot?",
        "DEVELOPER_ACCOUNT": "Hoe tevreden ben je over de mogelijkheid om aangepaste oplossingen te bouwen op HubSpot?",
        "DATA_QUALITY": "Hoe tevreden ben je over het beheren van gegevenskwaliteit in HubSpot?",
        "PLATFORM_DUPLICATE_INTERACTION": "Hoe tevreden ben je over het beheren van gegevenskwaliteit in HubSpot?",
        "CRM_DEVELOPMENT": "Hoe tevreden ben je over de mogelijkheid om aangepaste oplossingen te bouwen op HubSpot?",
        "CSAT_CRM_CUSTOM_CARDS": "Hoe tevreden ben je over hoe aangepast je account is in HubSpot?",
        "COMMERCE_HOME": "Hoe tevreden ben je over de mogelijkheid om aan de slag te gaan met Commerce Hub?",
        "IAH_EXPERIENCE": "Hoe tevreden ben je met je hulpervaring?",
        "FORMS_VIS_EXPERIENCE": "Hoe tevreden ben je over je vermogen om nieuwe leads vast te leggen met HubSpot?",
        "CSAT_SVH_AI_AGENT": "Hoe tevreden ben je met de klantondersteuning van HubSpot?",
        "DATA_MODEL_INTERACTION": "Hoe tevreden ben je over de mogelijkheid om de gegevens van je bedrijf in HubSpot toe te wijzen?",
        "CMS_GENERATE_PODCAST": "Hoe tevreden ben je over de mogelijkheid om inhoud te maken in HubSpot?",
        "PLATFORM_EDIT_PERMISSIONS": "Hoe tevreden ben je over de mogelijkheid om je gebruikers voor te bereiden op succes in HubSpot?",
        "CMS_remix": "Hoe tevreden ben je over de mogelijkheid om inhoud te maken in HubSpot?"
      },
      "wootric_recommend_target": "een collega",
      "followup_thank_you": "Bedankt!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Hoe gemakkelijk kreeg je toegang tot de HubSpot-functionaliteit in deze proefversie?",
        "SETUP_COMPLETE": "Hoe gemakkelijk was het om alles in te stellen voor HubSpot?",
        "ACADEMY": "Hoe gemakkelijk was het om de Academy te gebruiken?",
        "PURCHASE_EXPERIENCE": "Hoe gemakkelijk was het voor jou om HubSpot aan te kopen?"
      }
    },
    "PL": {
      "followup_question": "Co możemy zrobić, aby poprawić nasze usługi?",
      "followup_question_nps": "Co jest najważniejszym powodem otrzymania takiego wyniku?",
      "followup_placeholder": "Wyjaśnimy Ci powody przyznania takiej oceny, co pomoże ulepszyć witrynę.",
      "placeholder_text": "Wyjaśnimy Ci powody przyznania takiej oceny, co pomoże ulepszyć witrynę.",
      "send": "Wyślij",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Jak oceniasz działanie internetowego konta i funkcji rozliczeniowych?",
        "WORKFLOWS": "Jak oceniasz możliwość automatyzacji procesów w HubSpot?",
        "INBOX": "Jak oceniasz funkcjonalność zarządzania konwersacjami na platformie HubSpot?",
        "TRIAL_EXPIRATION": "Jak całościowo oceniasz okres próbny?",
        "TRIAL_EXPIRATION_DASHBOARD": "Jak całościowo oceniasz okres próbny?",
        "CMS_BRAND_SETTINGS": "Jak oceniasz możliwość zarządzania marką na platformie HubSpot?",
        "CMS_CONTENT_DETAIL": "Jak oceniasz możliwość zarządzania treścią sieci web na platformie HubSpot?",
        "CMS_DESIGN_MANAGER": "Jak oceniasz możliwość opracowania własnej witryny internetowej na platformie HubSpot?",
        "ADVANCED_BUILDER": "Jak oceniasz możliwość uzyskiwania analiz na platformie HubSpot?",
        "FORECASTING": "Jak oceniasz funkcjonalność zarządzania procesem sprzedaży na platformie HubSpot?",
        "FORECASTING_SEPG": "Jak oceniasz możliwość skutecznego zamykania transakcji na platformie HubSpot?",
        "SOCIAL": "Jak oceniasz możliwość angażowania odbiorców za pomocą platformy HubSpot?",
        "MARKETING_EMAIL": "Jak oceniasz możliwość pielęgnowania odbiorców za pomocą platformy HubSpot?",
        "FORMS": "Jak oceniasz możliwość przechwytywania nowych leadów za pomocą platformy HubSpot?",
        "CAMPAIGNS": "Jak oceniasz możliwość optymalizowania wysiłków marketingowych za pomocą platformy HubSpot?",
        "DEAL": "Jak oceniasz funkcjonalność zarządzania procesem sprzedaży na platformie HubSpot?",
        "ADS": "Jak oceniasz możliwość angażowania odbiorców za pomocą platformy HubSpot?",
        "TASKS": "Jak oceniasz funkcjonalność zarządzania wydajnością pracy działu sprzedaży na platformie HubSpot?",
        "SEQUENCES": "Jak oceniasz funkcjonalność zarządzania zasięgiem działań sprzedażowych na platformie HubSpot?",
        "QUOTES": "Jak oceniasz możliwość zarządzania wycenami na platformie HubSpot?",
        "ANALYTICS": "Jak oceniasz możliwość uzyskiwania analiz na platformie HubSpot?",
        "INDEX_CRM": "Jak oceniasz dostępność informacji niezbędnych do podjęcia działań w HubSpot?",
        "RECORD_CRM": "Jak oceniasz dostępność informacji niezbędnych do podjęcia działań w HubSpot?",
        "CHAT": "Jak oceniasz funkcjonalność zarządzania czatem na platformie HubSpot?",
        "DATA_SYNC": "Jak oceniasz zdolność synchronizowania danych na platformie HubSpot?",
        "CRM_LISTS": "Jak oceniasz zdolność skutecznego segmentowania danych w HubSpot?",
        "CRM_SETTINGS": "Jak oceniasz możliwość mapowania danych swojej firmy z HubSpot?",
        "PAYMENTS": "Jak oceniasz funkcjonalność zarządzania płatnościami na platformie HubSpot?",
        "ADMIN_SETUP": "Jak oceniasz możliwość przygotowania użytkowników pod kątem osiągnięcia sukcesu w HubSpot?",
        "IMPORT": "Jak oceniasz funkcjonalność importowania danych do HubSpot?",
        "ADMIN_GOVERNANCE": "W jakim stopniu podoba Ci się poziom kontroli, jaką masz nad swoim kontem HubSpot (jako administrator o rozszerzonych uprawnieniach portalu)?",
        "SINGLE_REPORT_VIEWER": "Jak oceniasz informacje otrzymywanie w ramach funkcji sprawozdawczych na platformie HubSpot?",
        "REPORT_LIBRARY": "Jak oceniasz gotowe raporty dostarczane przez HubSpot?",
        "JOURNEY_ANALYTICS": "Jak oceniasz informacje otrzymywane w ramach funkcji sprawozdawczych na platformie HubSpot?",
        "SINGLE_OBJECT_BUILDER": "Jak oceniasz informacje otrzymywane w ramach funkcji sprawozdawczych na platformie HubSpot?",
        "MULTI_TOUCH_ATTRIBUTION": "Jak oceniasz informacje otrzymywane w ramach funkcji sprawozdawczych na platformie HubSpot?",
        "REPORT_DASHBOARD_VIEW": "Jak oceniasz możliwość uzyskiwania analiz na platformie HubSpot?",
        "PROSPECTING_MEETINGS": "Jak oceniasz możliwość skutecznego kwalifikowania leadów na platformie HubSpot?",
        "PROSPECTING_MANAGE_SEQUENCES": "Jak oceniasz możliwość skutecznego kwalifikowania leadów na platformie HubSpot?",
        "PROSPECTING_TASKS": "Jak oceniasz możliwość skutecznego kwalifikowania leadów na platformie HubSpot?",
        "SALES_EXECUTION_DEAL_PIPELINE": "Jak oceniasz możliwość skutecznego zamykania transakcji na platformie HubSpot?",
        "SALES_EXECUTION_DEALS": "Jak oceniasz możliwość skutecznego zamykania transakcji na platformie HubSpot?",
        "SH_DEALS_WORKSPACE": "Jak oceniasz możliwość skutecznego zamykania transakcji na platformie HubSpot?",
        "PROSPECTING_WORKSPACE": "Jak oceniasz możliwość skutecznego kwalifikowania leadów na platformie HubSpot?",
        "CSAT_SVH_KNOWLEDGEBASE": "Jak oceniasz korzystanie ze wsparcia klienta HubSpot?",
        "CSAT_SVH_HELPDESK": "Jak oceniasz korzystanie ze wsparcia klienta HubSpot?",
        "CSAT_SVH_TICKET_DEFAULTS": "Jak oceniasz korzystanie ze wsparcia klienta HubSpot?",
        "CSAT_SVH_HELPDESK_USER": "Jak oceniasz korzystanie ze wsparcia klienta HubSpot?",
        "CSAT_SVH_LIVE_CHAT": "Jak oceniasz korzystanie ze wsparcia klienta HubSpot?",
        "CSAT_SVH_BOT_MESSENGER": "Jak oceniasz korzystanie ze wsparcia klienta HubSpot?",
        "CSAT_CRM_CONNECTED_APPS": "Jak oceniasz możliwość używania integracji w ramach platformy HubSpot?",
        "CSAT_CRM_CREATE_CUSTOM_OBJECT": "Jak oceniasz mapowanie danych firmy z platformą HubSpot?",
        "CSAT_CRM_VIEW_CUSTOM_OBJECTS": "Jak oceniasz mapowanie danych firmy z platformą HubSpot?",
        "HELPDESK_CREATE_VIEWS": "Jak oceniasz obsługę swoich klientów z platformą HubSpot?",
        "PRODUCTS": "Jak oceniasz możliwość zarządzania produktami firmy na platformie HubSpot?",
        "INVOICES": "Jak oceniasz możliwość fakturowania na platformie HubSpot?",
        "CMS_SEO_RECS": "Jak oceniasz otrzymane sugestie optymalizacji treści?",
        "FILE_MANAGER_PAGE_VIEW": "Jak oceniasz możliwość zarządzania zasobami cyfrowymi na platformie HubSpot?",
        "COMMERCE_SUBSCRIPTIONS": "Jak oceniasz funkcjonalność zarządzania subskrypcjami na platformie HubSpot?",
        "ECOSYSTEM_ACADEMY": "Jak oceniasz doświadczenia związane z uczeniem się na platformie HubSpot Academy?",
        "PAYMENT_LINKS": "Jak oceniasz możliwość akceptowania płatności w platformie HubSpot?",
        "ECOSYSTEM_APP_MARKETPLACE": "Jak oceniasz zdolność integracji innych aplikacji z HubSpot?",
        "ECOSYSTEM_NETWORK": "Jak oceniasz możliwość angażowania się w ramach społeczności Connect.com?",
        "DATASETS": "Jak oceniasz możliwość zarządzania jakością danych na platformie HubSpot?",
        "CTAS": "Jak oceniasz możliwość angażowania odbiorców przy wykorzystaniu platformy HubSpot?",
        "DEVELOPER_ACCOUNT": "Jak oceniasz zdolność do tworzenia niestandardowych rozwiązań na platformie HubSpot?",
        "DATA_QUALITY": "Jak oceniasz funkcjonalność zarządzania jakością danych na platformie HubSpot?",
        "PLATFORM_DUPLICATE_INTERACTION": "Jak oceniasz funkcjonalność zarządzania jakością danych na platformie HubSpot?",
        "CRM_DEVELOPMENT": "Jak oceniasz zdolność do tworzenia niestandardowych rozwiązań na platformie HubSpot?",
        "CSAT_CRM_CUSTOM_CARDS": "Jak oceniasz stopień dostosowywania konta w HubSpot?",
        "COMMERCE_HOME": "Jak oceniasz łatwość rozpoczęcia pracy z Commerce Hub?",
        "IAH_EXPERIENCE": "Jak oceniasz pomoc?",
        "FORMS_VIS_EXPERIENCE": "Jak oceniasz możliwość przechwytywania nowych leadów za pomocą platformy HubSpot?",
        "CSAT_SVH_AI_AGENT": "Jak oceniasz korzystanie ze wsparcia klienta HubSpot?",
        "DATA_MODEL_INTERACTION": "Jak oceniasz możliwość mapowania danych swojej firmy z HubSpot?",
        "CMS_GENERATE_PODCAST": "Jak oceniasz możliwość tworzenia treści na platformie HubSpot?",
        "PLATFORM_EDIT_PERMISSIONS": "Jak oceniasz możliwość przygotowania użytkowników pod kątem osiągnięcia sukcesu w HubSpot?",
        "CMS_remix": "Jak oceniasz możliwość tworzenia treści na platformie HubSpot?"
      },
      "wootric_recommend_target": "współpracownik lub kolega z pracy",
      "followup_thank_you": "Dziękujemy!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Jak łatwo było Ci ocenić funkcjonalność HubSpot podczas korzystania z wersji próbnej?",
        "SETUP_COMPLETE": "Jak łatwo było skonfigurować HubSpot?",
        "ACADEMY": "Jak łatwo było korzystać z HubSpot Academy?",
        "PURCHASE_EXPERIENCE": "Jak łatwy był zakup HubSpot?"
      }
    },
    "PT": {
      "followup_question": "O que poderíamos fazer para melhorar sua experiência?",
      "followup_question_nps": "Qual é o motivo mais importante para sua pontuação?",
      "followup_placeholder": "Ajude-nos a melhorar explicando sua pontuação.",
      "placeholder_text": "Ajude-nos a melhorar explicando sua pontuação.",
      "send": "Enviar",
      "csat_questions": {
        "ACCOUNTS_AND_BILLING": "Quão satisfeito você está com sua conta on-line e com a experiência de cobrança?",
        "WORKFLOWS": "Qual é o seu nível de satisfação com a capacidade de automatizar seus processos no HubSpot?",
        "INBOX": "Quão satisfeito você está com o gerenciamento de conversas na HubSpot?",
        "TRIAL_EXPIRATION": "Quão satisfeito você está com sua experiência geral na avaliação?",
        "TRIAL_EXPIRATION_DASHBOARD": "Quão satisfeito você está com sua experiência geral na avaliação?",
        "CMS_BRAND_SETTINGS": "Você está satisfeito com a capacidade de gerenciar sua marca no HubSpot?",
        "CMS_CONTENT_DETAIL": "Qual seu nível de satisfação com sua capacidade de gerenciar conteúdo web no HubSpot?",
        "CMS_DESIGN_MANAGER": "Qual seu nível de satisfação com sua capacidade de desenvolver seu site no HubSpot?",
        "ADVANCED_BUILDER": "Qual é o seu grau de satisfação com sua capacidade de obter insights no HubSpot?",
        "FORECASTING": "Quão satisfeito você está com o gerenciamento do seu pipeline de negócios na HubSpot?",
        "FORECASTING_SEPG": "Qual seu nível de satisfação com sua capacidade de fechar negócios de forma eficaz no HubSpot?",
        "SOCIAL": "Você está satisfeito com a capacidade de engajar seu público usando o HubSpot?",
        "MARKETING_EMAIL": "Você está satisfeito com a capacidade de nutrir seu público usando o HubSpot?",
        "FORMS": "Você está satisfeito com a capacidade de capturar novos leads usando a HubSpot IA?",
        "CAMPAIGNS": "Você está satisfeito com a capacidade de otimizar seus esforços de marketing usando a HubSpot IA?",
        "DEAL": "Quão satisfeito você está com o gerenciamento do seu pipeline de negócios na HubSpot?",
        "ADS": "Você está satisfeito com a capacidade de engajar seu público usando o HubSpot?",
        "TASKS": "Quão satisfeito você está com o gerenciamento da produtividade de vendas na HubSpot?",
        "SEQUENCES": "Quão satisfeito você está com o gerenciamento do alcance das vendas na HubSpot?",
        "QUOTES": "Qual seu nível de satisfação com sua capacidade de gerenciar orçamentos no HubSpot?",
        "ANALYTICS": "Qual é o seu grau de satisfação com sua capacidade de obter insights no HubSpot?",
        "INDEX_CRM": "Quão satisfeito você está com a disponibilidade das informações necessárias para agir no HubSpot?",
        "RECORD_CRM": "Quão satisfeito você está com a disponibilidade das informações necessárias para agir no HubSpot?",
        "CHAT": "Quão satisfeito você está com o gerenciamento de chats na HubSpot?",
        "DATA_SYNC": "Quão satisfeito você está com a capacidade de sincronizar dados na HubSpot?",
        "CRM_LISTS": "Quão satisfeito você está com sua capacidade de segmentar dados de forma eficaz no HubSpot?",
        "CRM_SETTINGS": "Qual é o seu grau de satisfação com a capacidade de mapear os dados da sua empresa no HubSpot?",
        "PAYMENTS": "Qual é seu nível de satisfação com o gerenciamento de pagamentos na HubSpot?",
        "ADMIN_SETUP": "Quão satisfeito você está com a capacidade de preparar seus usuários para o sucesso no HubSpot?",
        "IMPORT": "Qual é o seu grau de satisfação com a importação de dados para o HubSpot?",
        "ADMIN_GOVERNANCE": "Qual é seu grau de satisfação com o nível de controle que você tem sobre sua conta da HubSpot (como superadministrador do seu portal)?",
        "SINGLE_REPORT_VIEWER": "Como está sua satisfação em obter insights dos relatórios da HubSpot?",
        "REPORT_LIBRARY": "Qual é seu nível de satisfação com os relatórios prontos para uso fornecidos pela HubSpot?",
        "JOURNEY_ANALYTICS": "Como está sua satisfação em obter insights dos relatórios da HubSpot?",
        "SINGLE_OBJECT_BUILDER": "Como está sua satisfação em obter insights dos relatórios da HubSpot?",
        "MULTI_TOUCH_ATTRIBUTION": "Como está sua satisfação em obter insights dos relatórios da HubSpot?",
        "REPORT_DASHBOARD_VIEW": "Qual é o seu grau de satisfação com sua capacidade de obter insights no HubSpot?",
        "PROSPECTING_MEETINGS": "Qual seu nível de satisfação com sua capacidade de qualificar leads de forma eficaz no HubSpot?",
        "PROSPECTING_MANAGE_SEQUENCES": "Qual seu nível de satisfação com sua capacidade de qualificar leads de forma eficaz no HubSpot?",
        "PROSPECTING_TASKS": "Qual seu nível de satisfação com sua capacidade de qualificar leads de forma eficaz no HubSpot?",
        "SALES_EXECUTION_DEAL_PIPELINE": "Qual seu nível de satisfação com sua capacidade de fechar negócios de forma eficaz no HubSpot?",
        "SALES_EXECUTION_DEALS": "Qual seu nível de satisfação com sua capacidade de fechar negócios de forma eficaz no HubSpot?",
        "SH_DEALS_WORKSPACE": "Qual seu nível de satisfação com sua capacidade de fechar negócios de forma eficaz no HubSpot?",
        "PROSPECTING_WORKSPACE": "Qual seu nível de satisfação com sua capacidade de qualificar leads de forma eficaz no HubSpot?",
        "CSAT_SVH_KNOWLEDGEBASE": "Quão satisfeito você está com o suporte ao cliente da HubSpot?",
        "CSAT_SVH_HELPDESK": "Quão satisfeito você está com o suporte ao cliente da HubSpot?",
        "CSAT_SVH_TICKET_DEFAULTS": "Quão satisfeito você está com o suporte ao cliente da HubSpot?",
        "CSAT_SVH_HELPDESK_USER": "Quão satisfeito você está com o suporte ao cliente da HubSpot?",
        "CSAT_SVH_LIVE_CHAT": "Quão satisfeito você está com o suporte ao cliente da HubSpot?",
        "CSAT_SVH_BOT_MESSENGER": "Quão satisfeito você está com o suporte ao cliente da HubSpot?",
        "CSAT_CRM_CONNECTED_APPS": "Quão satisfeito você está com sua capacidade de usar integrações no HubSpot?",
        "CSAT_CRM_CREATE_CUSTOM_OBJECT": "Qual seu nível de satisfação com o mapeamento dos dados do seu negócio para o HubSpot?",
        "CSAT_CRM_VIEW_CUSTOM_OBJECTS": "Qual seu nível de satisfação com o mapeamento dos dados do seu negócio para o HubSpot?",
        "HELPDESK_CREATE_VIEWS": "Qual seu nível de satisfação com o suporte aos seus clientes no HubSpot?",
        "PRODUCTS": "Qual é o seu grau de satisfação com a capacidade de gerenciar os produtos da sua empresa no HubSpot?",
        "INVOICES": "Qual seu nível de satisfação com sua capacidade de criar faturas usando o HubSpot?",
        "CMS_SEO_RECS": "Qual é seu nível de satisfação com as sugestões de otimização de conteúdo que você recebeu?",
        "FILE_MANAGER_PAGE_VIEW": "Qual é o seu grau de satisfação com sua capacidade de gerenciar ativos digitais no HubSpot?",
        "COMMERCE_SUBSCRIPTIONS": "Quão satisfeito você está com o gerenciamento de assinaturas no HubSpot?",
        "ECOSYSTEM_ACADEMY": "Qual é o seu grau de satisfação com a experiência de aprendizado na HubSpot Academy?",
        "PAYMENT_LINKS": "Qual é o seu grau de satisfação com a capacidade de aceitar pagamentos no HubSpot?",
        "ECOSYSTEM_APP_MARKETPLACE": "Qual seu nível de satisfação com a capacidade de integrar outros apps ao HubSpot?",
        "ECOSYSTEM_NETWORK": "Qual seu nível de satisfação com sua capacidade de interagir com a comunidade Connect.com?",
        "DATASETS": "Qual seu nível de satisfação com sua capacidade de gerenciar qualidade de dados no HubSpot?",
        "CTAS": "Você está satisfeito com a capacidade de engajar seu público usando o HubSpot?",
        "DEVELOPER_ACCOUNT": "Qual seu nível de satisfação com sua capacidade de criar soluções personalizadas na HubSpot?",
        "DATA_QUALITY": "Qual o seu nível de satisfação em gerenciar a qualidade de dados na HubSpot?",
        "PLATFORM_DUPLICATE_INTERACTION": "Qual o seu nível de satisfação em gerenciar a qualidade de dados na HubSpot?",
        "CRM_DEVELOPMENT": "Qual seu nível de satisfação com sua capacidade de criar soluções personalizadas na HubSpot?",
        "CSAT_CRM_CUSTOM_CARDS": "Qual é o seu nível de satisfação com a capacidade de personalizar sua conta na HubSpot?",
        "COMMERCE_HOME": "Qual é o seu grau de satisfação com sua capacidade de começar a usar o Commerce Hub?",
        "IAH_EXPERIENCE": "Quão satisfeito você está com sua experiência de ajuda?",
        "FORMS_VIS_EXPERIENCE": "Você está satisfeito com a capacidade de capturar novos leads usando a HubSpot IA?",
        "CSAT_SVH_AI_AGENT": "Quão satisfeito você está com o suporte ao cliente da HubSpot?",
        "DATA_MODEL_INTERACTION": "Qual é o seu grau de satisfação com a capacidade de mapear os dados da sua empresa no HubSpot?",
        "CMS_GENERATE_PODCAST": "Qual seu nível de satisfação com a capacidade de criar conteúdo no HubSpot?",
        "PLATFORM_EDIT_PERMISSIONS": "Quão satisfeito você está com a capacidade de preparar seus usuários para o sucesso no HubSpot?",
        "CMS_remix": "Qual seu nível de satisfação com a capacidade de criar conteúdo no HubSpot?"
      },
      "wootric_recommend_target": "um colega",
      "followup_thank_you": "Obrigado!",
      "ces_questions": {
        "TRIAL_EXPIRATION": "Foi fácil avaliar a funcionalidade da HubSpot durante o teste?",
        "SETUP_COMPLETE": "Foi fácil começar a usar a HubSpot?",
        "ACADEMY": "Foi fácil usar o Academy?",
        "PURCHASE_EXPERIENCE": "Foi fácil comprar a HubSpot?"
      }
    }
  },
  "permission_labels": {
    "DE": "Ich bin damit einverstanden, dass HubSpot mich im Zusammenhang mit meinem Feedback kontaktiert.",
    "EN": "You can contact me about my feedback.",
    "ES": "Puedes contactarme para hablar sobre mis comentarios.",
    "FI": "Voit ottaa minuun yhteyttä antamani palautteen pohjalta.",
    "FR": "Vous pouvez me contacter au sujet de mes commentaires.",
    "IT": "Potete contattarmi in merito al mio feedback.",
    "JA": "フィードバックの詳細について、別途連絡を受けても構わない。",
    "NL": "Je mag contact met me opnemen over mijn feedback.",
    "PL": "Możesz się ze mną kontaktować w sprawie moich opinii.",
    "PT": "Você pode entrar em contato comigo sobre meu feedback."
  },
  "extra_questions": {
    "DE": {
      "question_text": "Was ist Ihre Rolle bzw. beschreibt Ihre Rolle am besten?",
      "button": "OK",
      "banner": "Noch eine Frage:",
      "answer_options": ["Rolle auswählen", "Agentur/Partner/Freiberufler", "Kundenservice-Leiter/Manager", "Supportmitarbeiter/Fachkraft", "Customer Success Manager/Account-Manager", "Datenanalyse", "Entwickler/Designer", "Marketer", "Marketing-Direktor/Manager", "Operations-Fachkraft", "Betriebsleiter/Manager", "Vertriebsleiter/Manager", "Vertriebsmitarbeiter", "Von allem etwas", "Sonstiger Leiter/Manager", "Sonstige", "Ich möchte nicht antworten"]
    },
    "EN": {
      "question_text": "Which one of these describes your role?",
      "button": "Done",
      "banner": "One more thing:",
      "answer_options": ["Choose one role", "Agency / Partner / Freelancer", "Customer Service Director / Manager", "Support Rep / Specialist", "Customer Success Manager / Account Manager", "Data Analyst", "Developer / Designer", "Marketer", "Marketing Director / Manager", "Operations Specialist", "Operations Director / Manager", "Sales Director / Manager", "Salesperson", "I do a little bit of everything", "Other Director / Manager", "Other", "I prefer not to say"]
    },
    "ES": {
      "question_text": "¿Cuál de las siguientes opciones describe tu rol?",
      "button": "Listo",
      "banner": "Una cosa más:",
      "answer_options": ["Elige un rol", "Agencia / Partner / Freelancer", "Director / Mánager de Servicio al Cliente", "Representante/Especialista en asistencia técnica", "Mánager del éxito del cliente/Mánager", "Analista de datos", "Desarrollador / Diseñador", "Especialista en marketing", "Dirección o gerencia de marketing", "Especialista en operaciones", "Director/mánager de operaciones", "Dirección o gerencia de ventas", "Ventas", "Hago un poco de todo", "Otro Director / Gerente", "Otro motivo", "Prefiero no decirlo"]
    },
    "FI": {
      "question_text": "Mikä näistä kuvailee rooliasi?",
      "button": "Valmis",
      "banner": "Vielä yksi juttu:",
      "answer_options": ["Valitse yksi rooli", "Edustus / Kumppani / Freelancer", "Asiakaspalvelun ohjaaja / Päällikkö", "Tukiedustaja / asiantuntija", "Customer Success Manager / tilinhoitaja", "Data-analyytikko", "Kehittäjä / Suunnittelija", "Markkinoija", "Markkinoinnin ohjaaja / Päällikkö", "Operaatioasiantuntija", "Operaatio-ohjaaja / Päällikkö", "Myynnin ohjaaja / Päällikkö", "Myyntihenkilö", "Teen vähän kaikkea", "Toinen ohjaaja / Päällikkö", "Muu", "En halua kertoa"]
    },
    "FR": {
      "question_text": "Comment décrivez-vous votre rôle ?",
      "button": "Terminé",
      "banner": "Une dernière chose :",
      "answer_options": ["Sélectionnez un rôle", "Agence / Partenaire / Freelance", "Directeur / Responsable du service client", "Spécialiste / représentant du support", "Customer Success Manager / Gestionnaire de compte", "Data Analyst", "Développeur / Designer", "Spécialiste marketing", "Directeur Marketing/Responsable", "Spécialiste des opérations", "Directeur / responsable des opérations", "Directeur des ventes/Responsable", "Membre de l'équipe commerciale", "Je touche un peu à tout", "Autre directeur / Responsable", "Autre", "Je préfère ne pas répondre"]
    },
    "IT": {
      "question_text": "Quale di questi descrive il tuo ruolo?",
      "button": "Operazione completata",
      "banner": "Ancora una cosa:",
      "answer_options": ["Scegli un ruolo", "Agenzia/Partner/Freelancer", "Direttore del servizio clienti/manager", "Rappresentante/Specialista supporto clienti", "Customer Success Manager/Account Manager", "Analista di dati", "Sviluppatore/designer", "Marketer", "Direttore marketing/responsabile", "Specialista delle operazioni", "Direttore/Responsabile delle operazioni", "Direttore delle vendite/responsabile", "Rappresentante delle vendite", "Faccio un po' di tutto", "Altro direttore/manager", "Altro", "Preferisco non dichiararlo"]
    },
    "JA": {
      "question_text": "あなたの役割に近いものをお選びください。",
      "button": "完了",
      "banner": "もう1点、お聞かせください。",
      "answer_options": ["役割を1つ選択", "代理店／パートナー／フリーランス", "カスタマーサービス責任者／管理者", "サポート担当者", "カスタマー サクセス マネージャー／アカウントマネージャー", "データ分析、データアナリスト", "開発者／デザイナー", "マーケティング担当者", "マーケティング責任者／管理者", "オペレーション担当者", "オペレーション責任者／管理者", "営業責任者／管理者", "営業担当者", "全事業領域に部分的に関与", "その他の責任者／管理者", "その他", "回答しない"]
    },
    "NL": {
      "question_text": "Welke van deze rollen is jouw rol?",
      "button": "Klaar",
      "banner": "Er is nog iets:",
      "answer_options": ["Kies een rol", "Bureau/Partner/Freelancer", "Directeur/manager van klantenservice", "Ondersteuningsmedewerkers/specialist", "Manager klantsucces/accountmanager", "Data-analist", "Ontwikkelaar/ontwerper", "Marketingmedewerker", "Marketingdirecteur/-manager", "Specialist Operations", "Operations-directeur/manager", "Salesdirecteur/-manager", "Verkoper", "Ik doe van alles wat", "Andere directeur/manager", "Overig", "Dat vertel ik liever niet"]
    },
    "PL": {
      "question_text": "Która z poniższych ról najlepiej Cię charakteryzuje?",
      "button": "Gotowe",
      "banner": "Jeszcze jedna sprawa:",
      "answer_options": ["Wybierz jedną rolę", "Agencja/partner/samozatrudnienie", "Dyrektor ds. obsługi klienta / menedżer", "Przedstawiciel ds. wsparcia / Specjalista", "Customer Success Manager / Menedżer konta", "Analityk danych", "Programista / projektant", "Marketer", "Dyrektor ds. marketingu / menedżer", "Specjalista ds. działań operacyjnych", "Dyrektor operacyjny / menedżer", "Dyrektor ds. sprzedaży / menedżer", "Sprzedawca", "Mam różne obowiązki", "Inny dyrektor / menedżer", "Inne", "Wolę nie podawać"]
    },
    "PT": {
      "question_text": "Qual delas descreve sua função?",
      "button": "Concluído",
      "banner": "Só mais uma coisa:",
      "answer_options": ["Escolha uma função", "Agência/Parceiro/Freelancer", "Diretor/Gerente de atendimento ao cliente", "Especialista/representante de suporte", "Gerente de sucesso do cliente/gerente de conta", "Analista de informações", "Desenvolvedor/Designer", "Profissional de marketing", "Diretor de Marketing/Gerente", "Especialista em operações", "Diretor de Operações/Gerente", "Diretor de Vendas/Gerente", "Vendedor", "Faço um pouco de tudo", "Outro diretor/gerente", "Outro", "Prefiro não dizer"]
    }
  },
  "field_required": {
    "DE": "Hierbei handelt es sich um ein Pflichtfeld.",
    "EN": "This field is required",
    "ES": "Este campo es obligatorio",
    "FI": "Tämä kenttä on pakollinen",
    "FR": "Ce champ est obligatoire",
    "IT": "Questo campo è obbligatorio",
    "JA": "このフィールドは必須です",
    "NL": "Dit veld is verplicht",
    "PL": "To pole jest wymagane",
    "PT": "Este campo é obrigatório"
  }
};