export const getAttachedElement = (attachTo, targetDocument = document) => {
  if (typeof attachTo === 'string') {
    return targetDocument.querySelector(attachTo);
  } else if (typeof attachTo === 'function') {
    return attachTo();
  }
  // 'attachTo' is already an element
  return attachTo;
};
export const getAttachedElements = attachTo => {
  if (!attachTo) {
    return [];
  }
  const attaches = Array.isArray(attachTo) ? attachTo : [attachTo];
  return attaches.map(attach => getAttachedElement(attach)).filter(element => Boolean(element));
};
export const isElementVisible = element => {
  if (!element) {
    return false;
  }
  return Boolean(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
};
export const toDOMRect = (floatingUIRect, padding) => {
  const {
    x,
    y,
    width,
    height
  } = floatingUIRect;
  return {
    left: x - padding,
    right: x + width + padding,
    top: y - padding,
    bottom: y + height + padding
  };
};